import React from 'react'
import { CSSProperties } from 'styled-components'
import FallbackImg from '../assets/FallbackImg.svg'
import { FallbackImgTiny } from '../assets/FallbackImg'

export const LogoWithFallback = ({
  alt,
  src,
  style,
}: {
  alt: string
  src: string | undefined
  style?: CSSProperties
}) =>
  src ? (
    <img
      src={src}
      alt={alt}
      style={style}
      loading="lazy"
      onError={(e) => {
        e.currentTarget.src = FallbackImg
        e.currentTarget.onerror = null
      }}
    />
  ) : (
    <FallbackImgTiny style={style} />
  )
