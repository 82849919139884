import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const ArrowLeft = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        fill="currentColor"
        d="m12 20-8-8 8-8 1.425 1.4-5.6 5.6H20v2H7.825l5.6 5.6Z"
      />
    </svg>
  </SVGWrapper>
)
export default ArrowLeft
