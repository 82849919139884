import React from 'react'
import styled from 'styled-components'
import ArrowLeft from '../assets/ArrowLeft'
import theme from '../Theme'
import { HeadingSmallest } from './Text'

const TextIconWrapper = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '4px',
  marginBottom: '24px',
  maxWidth: '200px',
})

const BackToPage = ({ path, pageName }: { path: string; pageName: string }) => (
  <TextIconWrapper href={path}>
    <ArrowLeft style={{ color: theme.color.textDefault, height: '24px' }} />
    <HeadingSmallest>Back to {pageName}</HeadingSmallest>
  </TextIconWrapper>
)

export default BackToPage
