import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'
import theme from '../Theme'

const FallbackImg = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={{ ...style }}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2664 22.4666L25.5331 21.7333V7.53328C25.5331 7.26661 25.422 7.02217 25.1997 6.79994C24.9775 6.57772 24.7331 6.46661 24.4664 6.46661H10.2664L9.53307 5.73328H24.4664C24.9775 5.73328 25.4053 5.9055 25.7497 6.24994C26.0942 6.59439 26.2664 7.02217 26.2664 7.53328V22.4666ZM27.7331 28.9333L25.0664 26.2666H7.53307C7.02196 26.2666 6.59418 26.0944 6.24974 25.7499C5.9053 25.4055 5.73307 24.9777 5.73307 24.4666V6.93328L3.06641 4.26661L3.59974 3.73328L28.2664 28.3999L27.7331 28.9333ZM10.5664 21.8333L12.7331 19.0333L14.8997 21.5666L17.3664 18.5333L6.46641 7.63328V24.4666C6.46641 24.7333 6.57752 24.9777 6.79974 25.1999C7.02196 25.4222 7.26641 25.5333 7.53307 25.5333H24.3664L20.6664 21.8333H10.5664Z"
        fill={theme.color.btnTxtDisabled}
      />
      <rect
        x="22.3984"
        y="2.40002"
        width="7.2"
        height="7.2"
        rx="0.5"
        fill="#FEF6F8"
      />
      <path
        d="M24.2 8.0625L26 6.2625L27.8 8.0625L28.0625 7.8L26.2625 6L28.0625 4.2L27.8 3.9375L26 5.7375L24.2 3.9375L23.9375 4.2L25.7375 6L23.9375 7.8L24.2 8.0625ZM22.6875 10C22.4958 10 22.3333 9.93333 22.2 9.8C22.0667 9.66667 22 9.50417 22 9.3125V2.6875C22 2.49583 22.0667 2.33333 22.2 2.2C22.3333 2.06667 22.4958 2 22.6875 2H29.3125C29.5042 2 29.6667 2.06667 29.8 2.2C29.9333 2.33333 30 2.49583 30 2.6875V9.3125C30 9.50417 29.9333 9.66667 29.8 9.8C29.6667 9.93333 29.5042 10 29.3125 10H22.6875ZM22.6875 9.6125H29.3125C29.3875 9.6125 29.4563 9.58125 29.5188 9.51875C29.5813 9.45625 29.6125 9.3875 29.6125 9.3125V2.6875C29.6125 2.6125 29.5813 2.54375 29.5188 2.48125C29.4563 2.41875 29.3875 2.3875 29.3125 2.3875H22.6875C22.6125 2.3875 22.5438 2.41875 22.4813 2.48125C22.4188 2.54375 22.3875 2.6125 22.3875 2.6875V9.3125C22.3875 9.3875 22.4188 9.45625 22.4813 9.51875C22.5438 9.58125 22.6125 9.6125 22.6875 9.6125Z"
        fill="#E01E5A"
      />
    </svg>
  </SVGWrapper>
)
export const FallbackImgTiny = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={{ ...style }}>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.95 14.8792L17.4458 14.375V4.6125C17.4458 4.42917 17.3694 4.26111 17.2167 4.10833C17.0639 3.95556 16.8958 3.87917 16.7125 3.87917H6.95L6.44583 3.375H16.7125C17.0639 3.375 17.358 3.4934 17.5948 3.73021C17.8316 3.96701 17.95 4.26111 17.95 4.6125V14.8792ZM18.9583 19.325L17.125 17.4917H5.07083C4.71944 17.4917 4.42535 17.3733 4.18854 17.1365C3.95174 16.8997 3.83333 16.6056 3.83333 16.2542V4.2L2 2.36667L2.36667 2L19.325 18.9583L18.9583 19.325ZM7.15625 14.4437L8.64583 12.5187L10.1354 14.2604L11.8312 12.175L4.3375 4.68125V16.2542C4.3375 16.4375 4.41389 16.6056 4.56667 16.7583C4.71944 16.9111 4.8875 16.9875 5.07083 16.9875H16.6437L14.1 14.4437H7.15625Z"
        fill={theme.color.btnTxtDisabled}
      />
      <rect x="14" y="2" width="6" height="6" rx="0.5" fill="#FEF6F8" />
      <path
        d="M15.4974 6.71871L16.9974 5.21871L18.4974 6.71871L18.7161 6.49996L17.2161 4.99996L18.7161 3.49996L18.4974 3.28121L16.9974 4.78121L15.4974 3.28121L15.2786 3.49996L16.7786 4.99996L15.2786 6.49996L15.4974 6.71871ZM14.237 8.33329C14.0773 8.33329 13.9418 8.27774 13.8307 8.16663C13.7196 8.05551 13.6641 7.9201 13.6641 7.76038V2.23954C13.6641 2.07982 13.7196 1.9444 13.8307 1.83329C13.9418 1.72218 14.0773 1.66663 14.237 1.66663H19.7578C19.9175 1.66663 20.053 1.72218 20.1641 1.83329C20.2752 1.9444 20.3307 2.07982 20.3307 2.23954V7.76038C20.3307 7.9201 20.2752 8.05551 20.1641 8.16663C20.053 8.27774 19.9175 8.33329 19.7578 8.33329H14.237ZM14.237 8.01038H19.7578C19.8203 8.01038 19.8776 7.98433 19.9297 7.93225C19.9818 7.88017 20.0078 7.82288 20.0078 7.76038V2.23954C20.0078 2.17704 19.9818 2.11975 19.9297 2.06767C19.8776 2.01558 19.8203 1.98954 19.7578 1.98954H14.237C14.1745 1.98954 14.1172 2.01558 14.0651 2.06767C14.013 2.11975 13.987 2.17704 13.987 2.23954V7.76038C13.987 7.82288 14.013 7.88017 14.0651 7.93225C14.1172 7.98433 14.1745 8.01038 14.237 8.01038Z"
        fill="#E01E5A"
      />
    </svg>
  </SVGWrapper>
)
export default FallbackImg
