import { CSSProperties } from 'react'
import styled from 'styled-components'

const SVGWrapper = styled('span')<{
  style?: any
  onClick?: () => void
}>`
  '& > svg' : {
    ${({ style }) => {
      return { ...style }
    }};
  }
  ${({ onClick }) => {
    return onClick ? { cursor: 'pointer' } : {}
  }};
`
export type SVGType = ({
  style,
}: {
  style?: CSSProperties | undefined
}) => JSX.Element
export default SVGWrapper
