import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const ContractsEmpty = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2363_58384)">
        <path
          d="M15.167 92.625V11.375L21.1253 16.6833L27.3003 11.375L33.4753 16.6833L39.6503 11.375L45.8253 16.6833L52.0003 11.375L58.1753 16.6833L64.3503 11.375L70.5253 16.6833L76.7003 11.375L82.8753 16.6833L88.8337 11.375V92.625L82.8753 87.3167L76.7003 92.625L70.5253 87.3167L64.3503 92.625L58.1753 87.3167L52.0003 92.625L45.8253 87.3167L39.6503 92.625L33.4753 87.3167L27.3003 92.625L21.1253 87.3167L15.167 92.625ZM26.867 69.9833H77.4587V65.1083H26.867V69.9833ZM26.867 54.4917H77.4587V49.5083H26.867V54.4917ZM26.867 38.7833H77.4587V33.9083H26.867V38.7833Z"
          fill="#3A393B"
        />
      </g>
      <circle cx="86.5" cy="86.5" r="13.125" fill="white" />
      <path
        d="M84.4622 92.3372L93.7878 83.0115L92.5789 81.8372L84.4622 89.9539L80.3865 85.8437L79.2122 87.0526L84.4622 92.3372ZM86.5 99.625C84.7039 99.625 83.0058 99.2796 81.4054 98.5888C79.8051 97.898 78.412 96.9597 77.2262 95.7738C76.0403 94.588 75.102 93.1949 74.4112 91.5946C73.7204 89.9942 73.375 88.2961 73.375 86.5C73.375 84.6809 73.7204 82.9712 74.4112 81.3709C75.102 79.7706 76.0403 78.3832 77.2262 77.2089C78.412 76.0345 79.8051 75.102 81.4054 74.4112C83.0058 73.7204 84.7039 73.375 86.5 73.375C88.3191 73.375 90.0288 73.7204 91.6291 74.4112C93.2294 75.102 94.6168 76.0345 95.7911 77.2089C96.9655 78.3832 97.898 79.7706 98.5888 81.3709C99.2796 82.9712 99.625 84.6809 99.625 86.5C99.625 88.2961 99.2796 89.9942 98.5888 91.5946C97.898 93.1949 96.9655 94.588 95.7911 95.7738C94.6168 96.9597 93.2294 97.898 91.6291 98.5888C90.0288 99.2796 88.3191 99.625 86.5 99.625Z"
        fill="#6011B1"
      />
      <defs>
        <filter
          id="filter0_d_2363_58384"
          x="11.167"
          y="8.375"
          width="77.6665"
          height="84.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2363_58384"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2363_58384"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </SVGWrapper>
)
export default ContractsEmpty
