import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const MinusCircle = ({
  style,
  onClick,
}: {
  style?: CSSProperties
  onClick?: () => void
}) => (
  <SVGWrapper
    style={{ width: '20px', height: '20px', ...style }}
    onClick={onClick}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7" fill="#E01E5A" />
      <path
        d="M10.0014 18C8.89494 18 7.85491 17.79 6.88132 17.3701C5.90772 16.9502 5.06082 16.3803 4.34064 15.6604C3.62044 14.9405 3.05028 14.094 2.63017 13.1209C2.21006 12.1477 2 11.1079 2 10.0014C2 8.89494 2.20997 7.85491 2.6299 6.88132C3.04983 5.90772 3.61972 5.06082 4.33959 4.34064C5.05946 3.62044 5.90598 3.05028 6.87915 2.63017C7.8523 2.21006 8.89212 2 9.99859 2C11.1051 2 12.1451 2.20997 13.1187 2.6299C14.0923 3.04983 14.9392 3.61972 15.6594 4.33959C16.3796 5.05946 16.9497 5.90598 17.3698 6.87915C17.7899 7.8523 18 8.89212 18 9.99859C18 11.1051 17.79 12.1451 17.3701 13.1187C16.9502 14.0923 16.3803 14.9392 15.6604 15.6594C14.9405 16.3796 14.094 16.9497 13.1209 17.3698C12.1477 17.7899 11.1079 18 10.0014 18ZM10 16.7369C11.8807 16.7369 13.4737 16.0842 14.779 14.779C16.0842 13.4737 16.7369 11.8807 16.7369 10C16.7369 8.11929 16.0842 6.52631 14.779 5.22104C13.4737 3.91577 11.8807 3.26314 10 3.26314C8.11929 3.26314 6.52631 3.91577 5.22104 5.22104C3.91577 6.52631 3.26314 8.11929 3.26314 10C3.26314 11.8807 3.91577 13.4737 5.22104 14.779C6.52631 16.0842 8.11929 16.7369 10 16.7369Z"
        fill="#E01E5A"
      />
      <path d="M6 10H14" stroke="white" strokeWidth="1.2" />
    </svg>
  </SVGWrapper>
)
export default MinusCircle
