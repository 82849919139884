import { CSSProperties } from 'styled-components'

const Ball = ({
  color,
  size,
  style,
  className,
}: {
  color: string
  size: number
  style?: CSSProperties
  className?: string
}) => (
  <div
    className={className}
    style={{
      height: `${size}px`,
      width: `${size}px`,
      backgroundColor: color,
      borderRadius: '50%',
      ...style,
    }}
  />
)
export default Ball
