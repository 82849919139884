import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const Loading = ({
  style,
  large,
}: {
  style?: CSSProperties
  large?: boolean
}) => (
  <SVGWrapper
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    }}
  >
    {large ? (
      <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="loading"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0"
          to="360"
          dur="1s"
          repeatCount="indefinite"
        />
        <path
          d="M84 44C84 34.2815 80.4618 24.8955 74.0465 17.5953C67.6312 10.2952 58.7775 5.5803 49.1396 4.33157C39.5017 3.08284 29.7388 5.38566 21.6749 10.8098C13.6109 16.234 7.79753 24.4084 5.32078 33.806C2.84402 43.2036 3.87332 53.1814 8.21638 61.8755C12.5594 70.5695 19.9192 77.3851 28.9206 81.0488C37.922 84.7125 47.9494 84.9738 57.1295 81.7838C66.3095 78.5939 74.0142 72.1709 78.8041 63.7148L71.1409 59.374C67.4056 65.9681 61.3973 70.9769 54.2386 73.4645C47.0798 75.9521 39.2603 75.7483 32.2408 72.8913C25.2213 70.0342 19.4821 64.7194 16.0953 57.9396C12.7085 51.1598 11.9059 43.3789 13.8373 36.0505C15.7687 28.7221 20.3021 22.3476 26.5905 18.1177C32.8789 13.8879 40.4921 12.0921 48.008 13.0659C55.5238 14.0396 62.428 17.7164 67.4308 23.4092C72.4336 29.1019 75.1927 36.4214 75.1927 44H84Z"
          fill="#6011B1"
        />
      </svg>
    ) : (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="loading"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0"
          to="360"
          dur="1s"
          repeatCount="indefinite"
        />
        <path
          d="M16 8C16 6.10783 15.3293 4.27691 14.1071 2.83246C12.8849 1.38801 11.1902 0.423529 9.32413 0.110344C7.45806 -0.202841 5.54139 0.155542 3.91456 1.12183C2.28773 2.08813 1.05606 3.59977 0.438331 5.38826C-0.179399 7.17676 -0.143201 9.12631 0.540496 10.8906C1.22419 12.655 2.51113 14.1199 4.17271 15.0251C5.83429 15.9303 7.76294 16.2173 9.6161 15.8351C11.4693 15.4528 13.1269 14.4261 14.2947 12.9373L12.4471 11.4881C11.6221 12.5399 10.4509 13.2652 9.14174 13.5353C7.83253 13.8053 6.46998 13.6026 5.29611 12.9631C4.12224 12.3235 3.21306 11.2886 2.73004 10.0422C2.24702 8.79571 2.22145 7.4184 2.65786 6.15487C3.09427 4.89134 3.96442 3.8234 5.11373 3.14074C6.26305 2.45808 7.61713 2.20489 8.93547 2.42615C10.2538 2.6474 11.451 3.32878 12.3145 4.34926C13.178 5.36973 13.6518 6.66323 13.6518 8H16Z"
          fill="#6011B1"
        />
      </svg>
    )}
  </SVGWrapper>
)

export default Loading
