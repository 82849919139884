import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ThreeDots from '../assets/ThreeDots'
import theme from '../Theme'
import { AppImage, Profile } from './Profile'
import { HeadingSmall } from './Text'
import { OptionsWrapper, Option, OptionSearch } from './Dropdown'
import { UserType } from '../Users'
import { changeUserRole, inviteUsers } from '../api/user'
import useAuth from '../hooks/useAuth'
import useImpersonation from '../hooks/useImpersonation'
import ArrowDropDown from '../assets/ArrowDropDown'
import { getFullName } from '../pages/Profile'
import {
  capitalize,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { Roles } from '../api/user'
import Loading from '../assets/Loading'
import Ball from '../assets/Ball'
import SortArrows from '../assets/SortArrows'
import Error from '../assets/Error'

export const TableWrapper = styled('table')({
  minWidth: '100%',
})

export const TableHead = styled('thead')({
  color: theme.color.textSubdued,
  backgroundColor: theme.color.backgroundSubdued,
  '& th': {
    border: `1px solid ${theme.color.border}`,
    position: 'relative',
  },
  '& tr td': {
    borderBottom: `1px solid ${theme.color.border}`,
  },
})

export const TableBody = styled('tbody')`
  color: ${theme.color.textDefault};
  background-color: ${theme.color.white};
  & > tr:first-child > td {
    border-top: none;
  }
  & tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }
  & tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }
  & tr:last-child td {
    border-bottom: 1px solid ${theme.color.border};
  }
  & > tr > td {
    background-color: ${theme.color.white};
  }
`

export const TableRow = styled('tr')<{ alert?: boolean }>`
  & > td {
    background-color: ${({ alert }) =>
      alert ? theme.color.backgroundError : ''};
    border-top: 1px solid ${theme.color.border};
  }
  & > td:first-child {
    border-left: 1px solid ${theme.color.border};
  }
  & > td:last-child {
    border-right: 1px solid ${theme.color.border};
  }

  thead & td {
    border-top: 1px solid ${theme.color.border};
  }
  thead & td:first-child {
    border-top-left-radius: 4px;
  }
  thead & td:last-child {
    border-top-right-radius: 4px;
  }
`

export const TableCell = styled('td')<{
  rightAlign?: boolean
  mw?: string
  w?: string
  onClick?: () => void
}>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'normal')};
  text-align: ${({ rightAlign }) => (rightAlign ? 'right' : 'left')};
  height: 64px;
  padding: 0 10px;
  position: relative;
  ${({ w }) => (w ? `width: ${w};` : '')}
  ${({ mw }) => (mw ? `max-width: ${mw};` : '')}
  & > span {
    display: inline;
    vertical-align: middle;
  }
  & > p {
    display: inline;
    vertical-align: middle;
    margin-left: 10px;
  }
`

export const TableCellLink = ({
  href,
  target,
  children,
  style,
}: {
  href: string
  target: string
  children: ReactNode
  style?: React.CSSProperties
}) => (
  <TableCell w="230px" style={style}>
    <a
      href={href}
      target={target}
      style={{
        color: 'inherit',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
      }}
    >
      {children}
    </a>
  </TableCell>
)

export const UserCell = ({
  hash,
  first_name,
  last_name,
  email,
  profile_picture,
}: {
  hash: string
  first_name?: string
  last_name?: string
  email: string
  profile_picture: string
}) => (
  <TableCellLink href={`/users/${hash}`} target="">
    {first_name ? (
      <>
        <Profile size="small" src={profile_picture} name={first_name} />
        {`${first_name} ${last_name}`}
      </>
    ) : (
      <>
        <Profile size="small" src={profile_picture} name={email} />
        {email}
      </>
    )}
  </TableCellLink>
)
export const ApplicationCell = ({
  id,
  logo,
  name,
  number,
  error,
  style,
}: {
  id: number
  logo: string | undefined
  name: string
  number?: number
  error?: string
  style?: React.CSSProperties
}) => (
  <TableCellLink
    href={`/product/${id}`}
    target=""
    style={{
      position: 'sticky',
      left: '45px',
      zIndex: 2,
      boxShadow: `3px 0 6px -3px ${theme.color.border}`,
    }}
  >
    <AppImage logo_url={logo} name={name} />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <span
        style={{
          display: 'inline-block',
          width: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...style,
        }}
      >
        {name}
        {number ? (
          <span
            style={{
              fontWeight: '400',
              fontSize: '14px',
              color: theme.color.textSubdued,
            }}
          >
            {` (${number})`}
          </span>
        ) : null}
      </span>
      {error ? (
        <span
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '4px',
            fontSize: '14px',
            color: theme.color.textSubdued,
          }}
        >
          <Error small />
          <span>{error}</span>
        </span>
      ) : null}
    </div>
  </TableCellLink>
)

export const ActionsCell = ({
  options,
}: {
  options: { [s: string]: () => void }
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])
  return (
    <TableCell w="50px" rightAlign>
      <ThreeDots
        r={ref}
        selected={showOptions}
        onClick={() => setShowOptions(!showOptions)}
      />
      {showOptions && (
        <div
          style={{
            position: 'absolute',
            bottom: '22px',
            left: '-65px',
            width: '125px',
          }}
        >
          <OptionsWrapper>
            {Object.keys(options).map((k, i) => (
              <Option
                key={i}
                onClick={() => {
                  options[k]()
                  setShowOptions(false)
                }}
              >
                {k}
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </TableCell>
  )
}

export const AccessLevelCell = ({
  modifiedUser,
  isAdminTable,
  roles,
  handleRoleChange,
}: {
  modifiedUser: UserType
  isAdminTable: boolean
  roles: Roles | undefined
  handleRoleChange: (modifiedUser: UserType) => void
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [accessLevel, setAccessLevel] = useState(modifiedUser.access_level)
  const { user, permissions } = useAuth()
  const { reqOrgId, impersonate, reqUserId } = useImpersonation()

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  const defaultAttrs = {
    first_name: modifiedUser.first_name,
    last_name: modifiedUser.last_name,
    email: modifiedUser.email,
    org_id: user ? user.org_id : -1,
  }
  if (!roles) {
    return <></>
  }

  const changeRole = async (role_id: number) => {
    if (!roles) {
      return
    }
    const role = roles.find((role) => role.id === role_id)
    const roleName = role ? capitalize(role.name) : ''
    let resp
    if (isAdminTable) {
      resp = await changeUserRole(
        {
          email: modifiedUser.email,
          role_id: role_id,
        },
        impersonate,
        reqOrgId,
        reqUserId
      )
    } else {
      resp = await inviteUsers(
        { ...defaultAttrs, role_id: role_id },
        impersonate,
        reqOrgId,
        reqUserId
      )
    }
    if (resp.error) {
      showToast(
        `Failed to move ${modifiedUser.email} to ${roleName}`,
        ToastType.ERROR
      )
      return
    }
    showToast(`${modifiedUser.email} is now ${roleName}`, ToastType.SUCCESS)
    setAccessLevel(roleName)
    modifiedUser.access_level = roleName
    handleRoleChange(modifiedUser)
  }
  if (!user || !permissions) {
    return <Loading />
  }

  if (
    user.email === modifiedUser.email ||
    !permissions.edit_managers_admins ||
    !permissions.edit_members
  ) {
    return (
      <TableCell style={{ paddingLeft: '18px' }}>
        {modifiedUser.access_level}
      </TableCell>
    )
  }

  return (
    <TableCell
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <DropdownCell
        ref={ref}
        isOpen={showOptions}
        onClick={() => setShowOptions(!showOptions)}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {capitalize(accessLevel)}
        </span>
        <ArrowDropDown />
      </DropdownCell>

      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            {roles
              .filter(
                (role) => accessLevel.toLowerCase() !== role.name.toLowerCase()
              )
              .map((role) => (
                <Option
                  key={role.id}
                  onClick={() => {
                    changeRole(role.id)
                    setShowOptions(false)
                  }}
                >
                  {capitalize(role.name)}
                </Option>
              ))}
          </OptionsWrapper>
        </div>
      )}
    </TableCell>
  )
}

export const DropdownCell = styled('div')<{
  isOpen: boolean
}>`
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isOpen }) =>
    isOpen ? theme.color.backgroundHover : 'inherit'};
  border-radius: 8px;
  padding: 0 8px;
  & > svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
  }
  & > * {
    pointer-events: none;
  }
`
type OwnerType = 'app' | 'procurement'

export const OwnerCell = ({
  appId,
  options,
  defaulOption,
  onChange,
  ownerType,
}: {
  appId: number
  options: UserType[]
  defaulOption: string
  onChange?: (appId: number, owner: string) => void
  ownerType: OwnerType
}) => {
  const ref = useRef(null)
  const refSearch = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<UserType[]>(options)
  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaulOption
  )
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onSelect = async (s: string) => {
    let path = '/product/owner'
    if (ownerType === 'procurement') {
      path = '/product/procurement_owner'
    }
    const productPath = addImpersonationParams(
      path,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const response = await backendRequest(productPath, {
      method: 'POST',
      body: JSON.stringify({
        appId,
        ownerHash: s,
      }),
    })
    if (response.error) {
      showToast(`Failed to assign product to owner`, ToastType.ERROR)
      return
    }
    onChange && onChange(appId, s)
    showToast(`Successfully updated product owner`, ToastType.SUCCESS)
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current && e.target !== refSearch.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  useEffect(() => {
    setSelectedOption(defaulOption)
  }, [defaulOption])

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase()
    if (value === '') {
      setFilteredOptions(options)
      return
    }
    const filtered = options.filter((o) => {
      const name = getFullName(o).toLowerCase()
      return name.includes(value)
    })
    setFilteredOptions(filtered)
  }

  return (
    <TableCell w="200px">
      <DropdownCell
        ref={ref}
        isOpen={showOptions}
        onClick={() => setShowOptions(!showOptions)}
      >
        {selectedOption ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
              minWidth: '120px',
            }}
          >
            <Profile name={selectedOption} />
            <span
              style={{
                maxWidth: '122px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedOption}
            </span>
          </span>
        ) : (
          <span style={{ minWidth: '120px' }}>Select an Owner</span>
        )}
        <ArrowDropDown />
      </DropdownCell>
      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            <OptionSearch
              onChange={onFilterChange}
              ref={refSearch}
              type="text"
              placeholder="Search"
            />
            {filteredOptions.map((o, i) => (
              <Option
                key={i}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={() => {
                  setSelectedOption(getFullName(o))
                  setShowOptions(false)
                  onSelect(o.hash)
                }}
              >
                {getFullName(o)}
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </TableCell>
  )
}
export const DepartmentCell = ({
  appId,
  options,
  defaulOption,
  onChange,
}: {
  appId: number
  options: string[]
  defaulOption: string
  onChange?: (appId: number, owner: string) => void
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>(defaulOption)
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onSelect = async (s: string) => {
    const productPath = addImpersonationParams(
      `/product/department`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const response = await backendRequest(productPath, {
      method: 'POST',
      body: JSON.stringify({
        appId,
        department: s,
      }),
    })
    if (response.error) {
      showToast(`Failed to assign product to department`, ToastType.ERROR)
      return
    }
    onChange && onChange(appId, s)
    showToast(`Successfully updated product department`, ToastType.SUCCESS)
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  useEffect(() => {
    setSelectedOption(defaulOption)
  }, [defaulOption])

  return (
    <TableCell mw="100px">
      <DropdownCell
        ref={ref}
        isOpen={showOptions}
        onClick={() => setShowOptions(!showOptions)}
      >
        {selectedOption}
        <ArrowDropDown />
      </DropdownCell>
      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            {options.map((o, i) => (
              <Option
                key={i}
                onClick={() => {
                  setSelectedOption(o)
                  setShowOptions(false)
                  onSelect(o)
                }}
              >
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {o}
                </span>
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </TableCell>
  )
}
export const StaticOwnerCell = ({ owner }: { owner: string }) => {
  const nameFallback = 'Not Assigned'
  return (
    <TableCell>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Profile name={owner} />
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {owner || nameFallback}
        </span>
      </div>
    </TableCell>
  )
}

const StyledChip = styled('span')<{ color: string }>`
  color: ${({ color }) => color};
`

export const RecommendationStatusCell = ({
  recommendationID,
  children,
  onReject,
}: {
  recommendationID: number
  children: string
  onReject: () => void
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(children)
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onSelect = async (s: string) => {
    if (s === selectedOption) {
      return
    }

    if (s.toLowerCase() === 'rejected') {
      onReject()
      return
    }

    setSelectedOption(s)
    const savingsPath = addImpersonationParams(
      `/report/savings_opportunities`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const response = await backendRequest(savingsPath, {
      method: 'POST',
      body: JSON.stringify({
        new_status: s,
        recommendation_id: recommendationID,
      }),
    })
    if (response.error) {
      showToast(`Failed to move recommendation to ${s} status`, ToastType.ERROR)
      return
    }
  }

  const options: string[] = ['To Do', 'In Review', 'Done', 'Rejected']

  const getTextColor = (option: string) => {
    switch (option) {
      case 'To Do':
        return theme.color.textPurple
      case 'In Review':
        return theme.color.ChipYellow
      case 'Done':
        return theme.color.ChipGreen
      case 'Rejected':
      default:
        return theme.color.btnTxtDisabled
    }
  }
  const getIconColor = (option: string) => {
    switch (option) {
      case 'In Review':
        return theme.color.warningYellow
      case 'Done':
        return theme.color.successGreen
      case 'To Do':
      case 'Rejected':
      default:
        return getTextColor(option)
    }
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  return (
    <>
      <TableCell
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DropdownCell
          ref={ref}
          isOpen={showOptions}
          onClick={() => setShowOptions(!showOptions)}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Ball color={getIconColor(selectedOption)} size={16} />
            <StyledChip color={getTextColor(selectedOption)}>
              {selectedOption}
            </StyledChip>
          </div>
          <ArrowDropDown />
        </DropdownCell>
        {showOptions && (
          <div style={{ position: 'relative' }}>
            <OptionsWrapper>
              {options.map((o, i) => (
                <Option
                  key={i}
                  onClick={() => {
                    setShowOptions(false)
                    onSelect(o)
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Ball color={getIconColor(o)} size={16} />
                    <StyledChip color={getTextColor(o)}>{o}</StyledChip>
                  </div>
                </Option>
              ))}
            </OptionsWrapper>
          </div>
        )}
      </TableCell>
    </>
  )
}

enum ContractStatus {
  TODO = 'To Do',
  NEGOTIATION = 'In Negotiation',
  DISCUSS = 'Need To Discuss',
  RENEWAL = 'Planned Renewal',
  DEPRECATION = 'Planned Deprecation',
  CANCELLED = 'Cancelled',
}

export const ContractStatusCell = ({
  appID,
  defaultValue,
}: {
  appID: number
  defaultValue: string
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    let newValue = defaultValue
      .replaceAll('_', ' ')
      .toLowerCase()
      .replaceAll(/\b\w/g, (s) => s.toUpperCase())

    if (newValue === '') {
      newValue = ContractStatus.TODO
    }
    setSelectedOption(newValue)
  }, [defaultValue])

  const onSelect = async (s: string) => {
    if (s === selectedOption) {
      return
    }

    setSelectedOption(s)
    const savingsPath = addImpersonationParams(
      `/contract_status`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const newStatus = s.toLowerCase().replaceAll(' ', '_')
    const response = await backendRequest(savingsPath, {
      method: 'POST',
      body: JSON.stringify({
        new_status: newStatus,
        application_id: appID,
      }),
    })
    if (response.error) {
      showToast(`Failed to move contract to ${s} status`, ToastType.ERROR)
      return
    }
  }

  const options: ContractStatus[] = [
    ContractStatus.TODO,
    ContractStatus.NEGOTIATION,
    ContractStatus.DISCUSS,
    ContractStatus.RENEWAL,
    ContractStatus.DEPRECATION,
    ContractStatus.CANCELLED,
  ]

  const getTextColor = (option: string) => {
    switch (option) {
      case ContractStatus.TODO:
        return theme.color.textPurple
      case ContractStatus.NEGOTIATION:
        return theme.color.textBlue
      case ContractStatus.DISCUSS:
        return theme.color.ChipYellow
      case ContractStatus.RENEWAL:
        return theme.color.ChipGreen
      case ContractStatus.DEPRECATION:
        return theme.color.StatusRed
      case ContractStatus.CANCELLED:
      default:
        return theme.color.btnTxtDisabled
    }
  }
  const getIconColor = (option: string) => {
    switch (option) {
      case ContractStatus.DISCUSS:
        return theme.color.warningYellow
      case ContractStatus.RENEWAL:
        return theme.color.successGreen
      case ContractStatus.NEGOTIATION:
        return theme.color.textBlue
      case ContractStatus.TODO:
      case ContractStatus.DEPRECATION:
      case ContractStatus.CANCELLED:
      default:
        return getTextColor(option)
    }
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  return (
    <>
      <TableCell style={{ minWidth: '135px' }}>
        <DropdownCell
          ref={ref}
          isOpen={showOptions}
          onClick={() => setShowOptions(!showOptions)}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Ball color={getIconColor(selectedOption)} size={16} />
            <StyledChip color={getTextColor(selectedOption)}>
              {selectedOption}
            </StyledChip>
          </div>
          <ArrowDropDown />
        </DropdownCell>
        {showOptions && (
          <div style={{ position: 'relative' }}>
            <OptionsWrapper>
              {options.map((o, i) => (
                <Option
                  key={i}
                  onClick={() => {
                    setShowOptions(false)
                    onSelect(o)
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Ball color={getIconColor(o)} size={16} />
                    <StyledChip color={getTextColor(o)}>{o}</StyledChip>
                  </div>
                </Option>
              ))}
            </OptionsWrapper>
          </div>
        )}
      </TableCell>
    </>
  )
}

export const AutoRenewCell = ({
  appID,
  defaultValue,
}: {
  appID: number
  defaultValue: boolean | null
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    defaultValue === null ? '-' : defaultValue ? 'Yes' : 'No'
  )

  useEffect(() => {
    setSelectedOption(defaultValue === null ? '-' : defaultValue ? 'Yes' : 'No')
  }, [defaultValue])

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onSelect = async (s: string) => {
    if (s === selectedOption) {
      return
    }

    setSelectedOption(s)
    const savingsPath = addImpersonationParams(
      `/contract_status`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const auto_renew = s === '-' ? null : s === 'Yes'
    const response = await backendRequest(savingsPath, {
      method: 'POST',
      body: JSON.stringify({
        auto_renew,
        application_id: appID,
      }),
    })
    if (response.error) {
      showToast(`Failed to move contract to ${s} status`, ToastType.ERROR)
      return
    }
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  return (
    <>
      <TableCell style={{ minWidth: '135px' }}>
        <DropdownCell
          ref={ref}
          isOpen={showOptions}
          onClick={() => setShowOptions(!showOptions)}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {selectedOption}
          </div>
          <ArrowDropDown />
        </DropdownCell>
        {showOptions && (
          <div style={{ position: 'relative' }}>
            <OptionsWrapper>
              {['Yes', 'No'].map((o, i) => (
                <Option
                  key={i}
                  onClick={() => {
                    setShowOptions(false)
                    onSelect(o)
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    {o}
                  </div>
                </Option>
              ))}
            </OptionsWrapper>
          </div>
        )}
      </TableCell>
    </>
  )
}

const EmptyTableCell = styled('div')`
  border-top: 1px solid ${theme.color.border};
  border-bottom: 1px solid ${theme.color.border};
  margin: 15px 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EmptyTableBody = ({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <EmptyTableCell>
        <HeadingSmall>No data to display</HeadingSmall>
      </EmptyTableCell>
    </TableCell>
  </TableRow>
)

export const SortArrow = styled(ArrowDropDown)({
  display: 'flex',
  alignItems: 'flex-end',
  height: '34px',
  color: theme.color.textPurple,
})

export enum SortOrder {
  ASC = 1,
  DESC = -1,
}

export const SortableHeader = ({
  children,
  onClick,
  sortOrder,
  isSorted,
  mw,
  w,
  style,
}: {
  children: ReactNode
  onClick: () => void
  sortOrder: SortOrder
  isSorted: boolean
  mw?: string
  w?: string
  style?: React.CSSProperties
}) => (
  <TableCell onClick={onClick} w={w} mw={mw} style={style}>
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
      }}
    >
      {children}
      {isSorted ? (
        <SortArrow
          style={{
            transform: sortOrder === SortOrder.ASC ? 'rotate(180deg)' : 'none',
          }}
        />
      ) : (
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '16px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SortArrows />
        </span>
      )}
    </span>
  </TableCell>
)

export const ArrowDownCell = ({
  onClick,
  open,
}: {
  onClick: () => void
  open: boolean
}) => (
  <TableCell w="15px" style={{ position: 'sticky', left: '0', zIndex: 2 }}>
    <ArrowDropDown
      onClick={onClick}
      style={{
        display: 'inline-block',
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    />
  </TableCell>
)

export const SubTable = styled(TableRow)`
  background-color: ${theme.color.backgroundSubdued};
`
export const SubTableHead = styled('thead')`
  & > tr > td {
    border: none;
  }
`
export const SubTableRow = styled('tr')`
  & > td {
    border-top: 1px solid ${theme.color.border};
    height: 30px;
    padding: 10px 20px;
    border-bottom: none !important;
  }
  background-color: ${theme.color.backgroundSubdued};
`

export const SubTableCell = styled('td')`
  font-size: 14px;
  width: 100px;
  vertical-align: middle;
  &:last-child {
    border-top: none !important;
  }
`
export const SubTableHeading = styled('td')`
  font-size: 14px;
  width: 300px;
  vertical-align: middle;
  font-weight: 500;
`

export const TitleCell = styled(SubTableCell)`
  vertical-align: top;
  padding: 24px 48px !important;
  width: 400px;
  font-size: 16px;
  font-weight: 600;
`

const Switch = styled('label')`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`
const RoundSlider = styled('span')`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.color.btnTxtDisabled};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 17px;

  &:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

const Input = styled('input')`
  &:checked + .slider {
    background-color: ${theme.color.textPurple};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${theme.color.textPurple};
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`

export const SliderCell = ({
  appId,
  state,
  onClick,
}: {
  appId: number
  state: boolean
  onClick: (appId: number, checked: boolean) => void
}) => {
  const [checked, setChecked] = useState(state)

  useEffect(() => {
    setChecked(state)
  }, [state])

  const { reqOrgId, impersonate, reqUserId } = useImpersonation()

  const handleChange = async () => {
    const productPath = addImpersonationParams(
      `/product/approve`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const response = await backendRequest(productPath, {
      method: 'POST',
      body: JSON.stringify({
        app_id: appId,
        approve: !checked,
      }),
    })
    if (response.error) {
      showToast(`Failed to approve product`, ToastType.ERROR)
      return
    }
    onClick(appId, !checked)
    if (!checked) {
      showToast(`Successfully approved product`, ToastType.SUCCESS)
    } else {
      showToast(`Successfully disapproved product`, ToastType.WARNING)
    }
    setChecked(!checked)
  }

  return (
    <TableCell
      w="80px"
      style={{
        textAlign: 'center',
        position: 'sticky',
        right: '0',
        backgroundColor: theme.color.white,
        boxShadow: `-3px 0 6px -3px ${theme.color.border}`,
      }}
    >
      <Switch className="switch">
        <Input
          readOnly
          type="checkbox"
          defaultChecked={checked}
          checked={checked}
          onClick={handleChange}
        />
        <RoundSlider className="slider" />
      </Switch>
    </TableCell>
  )
}
