import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const UserTableEmpty = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2369_60025)">
        <path
          d="M52 47.6667C63.6278 47.6667 73.0347 45.8972 80.2208 42.3583C87.407 38.8194 91 34.8111 91 30.3333C91 25.7833 87.407 21.7569 80.2208 18.2542C73.0347 14.7514 63.6278 13 52 13C40.3722 13 30.9653 14.7514 23.7792 18.2542C16.5931 21.7569 13 25.7833 13 30.3333C13 34.8111 16.5931 38.8194 23.7792 42.3583C30.9653 45.8972 40.3722 47.6667 52 47.6667ZM52 58.5C55.6111 58.5 59.6556 58.1931 64.1333 57.5792C68.6111 56.9653 72.8542 56.0083 76.8625 54.7083C80.8708 53.4083 84.2292 51.7292 86.9375 49.6708C89.6458 47.6125 91 45.1389 91 42.25V53.0833C91 55.9722 89.6458 58.4458 86.9375 60.5042C84.2292 62.5625 80.8708 64.2417 76.8625 65.5417C72.8542 66.8417 68.6111 67.7986 64.1333 68.4125C59.6556 69.0264 55.6111 69.3333 52 69.3333C48.4611 69.3333 44.4347 69.0264 39.9208 68.4125C35.4069 67.7986 31.1458 66.8236 27.1375 65.4875C23.1292 64.1514 19.7708 62.4542 17.0625 60.3958C14.3542 58.3375 13 55.9 13 53.0833V42.25C13 45.0667 14.3542 47.5042 17.0625 49.5625C19.7708 51.6208 23.1292 53.3181 27.1375 54.6542C31.1458 55.9903 35.4069 56.9653 39.9208 57.5792C44.4347 58.1931 48.4611 58.5 52 58.5ZM52 80.1667C55.6111 80.1667 59.6556 79.8597 64.1333 79.2458C68.6111 78.632 72.8542 77.675 76.8625 76.375C80.8708 75.075 84.2292 73.3958 86.9375 71.3375C89.6458 69.2792 91 66.8056 91 63.9167V74.75C91 77.6389 89.6458 80.1125 86.9375 82.1708C84.2292 84.2292 80.8708 85.9083 76.8625 87.2083C72.8542 88.5083 68.6111 89.4653 64.1333 90.0792C59.6556 90.6931 55.6111 91 52 91C48.4611 91 44.4347 90.6931 39.9208 90.0792C35.4069 89.4653 31.1458 88.4903 27.1375 87.1542C23.1292 85.8181 19.7708 84.1208 17.0625 82.0625C14.3542 80.0042 13 77.5667 13 74.75V63.9167C13 66.7333 14.3542 69.1708 17.0625 71.2292C19.7708 73.2875 23.1292 74.9847 27.1375 76.3208C31.1458 77.6569 35.4069 78.632 39.9208 79.2458C44.4347 79.8597 48.4611 80.1667 52 80.1667Z"
          fill="#3A393B"
        />
      </g>
      <circle cx="82.3359" cy="82.333" r="13" fill="white" />
      <path
        d="M75.5621 91.0001L82.3329 84.2292L89.1037 91.0001L90.9996 89.1042L84.2287 82.3334L90.9996 75.5626L89.1037 73.6667L82.3329 80.4376L75.5621 73.6667L73.6662 75.5626L80.4371 82.3334L73.6662 89.1042L75.5621 91.0001ZM82.3329 100.389C79.8653 100.389 77.5331 99.915 75.3364 98.9671C73.1396 98.0191 71.2212 96.7252 69.5812 95.0851C67.9411 93.4451 66.6471 91.5267 65.6992 89.3299C64.7513 87.1332 64.2773 84.801 64.2773 82.3334C64.2773 79.8357 64.7513 77.4885 65.6992 75.2917C66.6471 73.095 67.9411 71.1841 69.5812 69.5591C71.2212 67.9341 73.1396 66.6476 75.3364 65.6997C77.5331 64.7518 79.8653 64.2778 82.3329 64.2778C84.8306 64.2778 87.1778 64.7518 89.3746 65.6997C91.5713 66.6476 93.4822 67.9341 95.1072 69.5591C96.7322 71.1841 98.0187 73.095 98.9666 75.2917C99.9145 77.4885 100.388 79.8357 100.388 82.3334C100.388 84.801 99.9145 87.1332 98.9666 89.3299C98.0187 91.5267 96.7322 93.4451 95.1072 95.0851C93.4822 96.7252 91.5713 98.0191 89.3746 98.9671C87.1778 99.915 84.8306 100.389 82.3329 100.389Z"
        fill="#6011B1"
      />
      <defs>
        <filter
          id="filter0_d_2369_60025"
          x="13"
          y="13"
          width="78"
          height="82"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2369_60025"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2369_60025"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </SVGWrapper>
)
export default UserTableEmpty
