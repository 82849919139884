import styled from 'styled-components'
import theme from '../Theme'
import { textStyle } from './Text'

export const PageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '56px',
})

export const LoginCard = styled('div')({
  width: '530px',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  border: `1px solid ${theme.color.backgroundPurple}`,
  boxSizing: 'border-box',
  boxShadow: `-16px 16px 0px ${theme.color.backgroundPurple}`,
})

export const WelcomeText = styled('h1')({
  fontSize: '40px',
  ...textStyle,
})

export const PurpleLink = styled('a')({
  color: theme.color.textPurple,
  textAlign: 'right',
  fontSize: '14px',
  margin: '8px 0 40px',
})
