import { useEffect, useState } from 'react'
import { HeadingSmallest } from '../../components/Text'
import Divider from '../../components/Divider'
import {
  PageWrapper,
  PurpleLink,
  WelcomeText,
} from '../../components/LoginComponents'
import Loading from '../../assets/Loading'
import useAuth from '../../hooks/useAuth'

export default function LoginCallback() {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState('')

  const { loginWithOkta } = useAuth()

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(
        window.location.href.substring(window.location.href.indexOf('?'))
      )
      // clear query string without refreshing the page. prevents double requests by state change
      window.history.replaceState({}, '', '/login/callback')

      if (params.get('error') || !params.get('code')) {
        return
      }

      setError(await loginWithOkta(params.get('code') || ''))

      setLoading(false)
    }
    handleCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <PageWrapper>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <WelcomeText>Welcome to Diminish</WelcomeText>
        <Divider style={{ margin: '16px 0' }} />
        {error ? (
          <HeadingSmallest color="errorRed" style={{ marginBottom: '24px' }}>
            {error}
          </HeadingSmallest>
        ) : (
          <HeadingSmallest color="textSubdued" style={{ marginBottom: '24px' }}>
            Please wait while we validate your credentials
          </HeadingSmallest>
        )}
        <PurpleLink href="/login">Back to Login</PurpleLink>
        <Divider />
      </div>
    </PageWrapper>
  )
}
