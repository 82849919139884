import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ToastContainer } from 'react-toastify'

//TODO: turn off Sentry from Dev if this get too noisy
if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://bafe20f316d84b45bca88a3134642a91@o1155789.ingest.sentry.io/4505047519854592',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, // When we have more traffic, lower this number in production
  })
}

const appRoot = document.getElementById('root')
if (appRoot) {
  ReactDOM.createRoot(appRoot).render(
    <React.StrictMode>
      <App />
      <ToastContainer closeOnClick={false} />
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.error))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
