import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import * as userApi from '../api/user'
import Cookies from 'js-cookie'
import { ClearImpersonationStorage } from '../utils/storage'
import { showToast, ToastType } from '../utils/toastify'

interface AuthContextType {
  user?: userApi.User
  permissions?: userApi.Permissions
  canImpersonate: boolean
  loading: boolean
  loadingInitial: boolean
  error?: any
  getOktaSSO: (
    domain: string
  ) => Promise<[{ url: string; client_id: string }, string]>
  loginWithOkta: (code: string) => Promise<string>
  login: (loginData: userApi.LoginForm) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export function AuthProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [user, setUser] = useState<userApi.User>()
  const [permissions, setPermissions] = useState<userApi.Permissions>()
  const [canImpersonate, setCanImpersonate] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true)

  const location = useLocation()

  useEffect(
    () => {
      if (error) setError(null)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  )

  useEffect(() => {
    if (
      Cookies.get('can_impersonate') &&
      Cookies.get('can_impersonate') === 'true'
    ) {
      setCanImpersonate(true)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/login/okta') {
      setLoadingInitial(false)
      return
    }
    const getContext = async () => {
      try {
        const u = await userApi.getCurrentUser()
        if (u.error) {
          setUser(undefined)
          ClearImpersonationStorage()
        } else {
          setUser(u)
          if (
            Cookies.get('can_impersonate') &&
            Cookies.get('can_impersonate') === 'true'
          ) {
            setCanImpersonate(true)
          }
        }
        await getPermissions()
      } catch (e) {
        setError(e)
      }
      setLoadingInitial(false)
    }
    getContext()
  }, [location.pathname])

  async function getPermissions() {
    const p = await userApi.permissions()
    if ('error' in p) {
      setPermissions(undefined)
    } else {
      setPermissions(p)
    }
  }

  const getOktaSSO = async (
    domain: string
  ): Promise<[{ url: string; client_id: string }, string]> => {
    const apiResp = await fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/login/okta?domain=${domain}`,
      {
        credentials: 'include',
      }
    )
    const resp = await apiResp.json()
    if (resp.error) {
      return [
        {
          url: '',
          client_id: '',
        },
        resp.error,
      ]
    }
    window.localStorage.setItem('okta_sso_url', resp.url)

    return [resp, '']
  }

  const loginWithOkta = async (code: string) => {
    setLoading(true)

    const ssoURL = window.localStorage.getItem('okta_sso_url')
    window.localStorage.removeItem('okta_sso_url')

    const apiResp = await fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/login/okta`,
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          code: code,
          sso_url: ssoURL,
        }),
      }
    )
    const resp = await apiResp.json()

    if (resp.error) {
      if (resp.error === 'user must set password to login') {
        window.location.href = resp.redirect_url

        return
      }
      showToast(
        `Failed to login with Okta. Please contact support.`,
        ToastType.ERROR
      )

      const errors = [
        'user must login with Okta',
        'user does have access to Diminish',
        'Okta SSO is not enabled. Please contact your administrator.',
      ]
      if (errors.includes(resp.error)) {
        return resp.error
      }
      return
    }
    setUser(user)

    if (
      Cookies.get('can_impersonate') &&
      Cookies.get('can_impersonate') === 'true'
    ) {
      setCanImpersonate(true)
    }

    await getPermissions()
    const redirect = new URLSearchParams(location.search).get('redirect')
    window.location.href = redirect || '/'
    setLoading(false)
  }
  async function login(loginData: userApi.LoginForm) {
    setLoading(true)

    const resp = await userApi.login(loginData)
    if (resp.error) {
      setError(resp.error)
    } else {
      setUser(user)

      if (
        Cookies.get('can_impersonate') &&
        Cookies.get('can_impersonate') === 'true'
      ) {
        setCanImpersonate(true)
      }

      await getPermissions()
      const redirect = new URLSearchParams(location.search).get('redirect')
      window.location.href = redirect || '/'
    }
    setLoading(false)
  }

  async function logout() {
    await userApi.logout()
    setUser(undefined)
    setPermissions(undefined)
    ClearImpersonationStorage()
  }

  const memoedValue = useMemo(
    () => ({
      user,
      permissions,
      canImpersonate,
      loading,
      loadingInitial,
      error,
      getOktaSSO,
      loginWithOkta,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, permissions, loading, error, loadingInitial]
  )

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
