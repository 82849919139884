import React, { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'
import CloseX from '../assets/CloseX'
import theme from '../Theme'

const ModalContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 100,
})

const ModalBrackground = styled('div')({
  backgroundColor: 'rgba(48, 32, 71, 0.4)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  overflow: 'auto',
})

const ModalBox = styled('div')<{ style: CSSProperties }>`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.backgroundPurple};
  border-radius: 5px;
  padding: 25px;
  z-index: 1;
  position: fixed;
  top: 10%;
  ${({ style }) => {
    return { ...style }
  }};
`

const Modal = ({
  style,
  onDismiss,
  children,
}: {
  style: CSSProperties
  onDismiss?: () => void
  children: ReactNode
}) => (
  <>
    <ModalContainer>
      <ModalBrackground onClick={onDismiss} />
      <ModalBox style={style} onClick={() => {}}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            height: '0px',
          }}
        >
          {onDismiss && (
            <CloseX
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={onDismiss}
            />
          )}
        </div>
        {children}
      </ModalBox>
    </ModalContainer>
  </>
)

export const ButtonWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  marginTop: '8px',
})

export default Modal
