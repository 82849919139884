import { FileUploader } from 'react-drag-drop-files'
import { HeadingSmallest } from './Text'
import theme from '../Theme'
import Paper from '../assets/Paper'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import CloseX from '../assets/CloseX'

export const Uploader = ({
  disabled,
  multiple,
  handleChange,
  fileTypes,
}: {
  disabled: boolean
  multiple: boolean
  handleChange: (file: any) => void
  fileTypes: string[]
}) => {
  const disabledBg = disabled
    ? theme.color.backgroundSubdued
    : theme.color.white
  return (
    <FileUploader
      disabled={disabled}
      multiple={multiple}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
    >
      <span
        style={{
          border: `2px dashed ${theme.color.border}`,
          height: '130px',
          margin: '16px 0',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: disabledBg,
        }}
      >
        <HeadingSmallest>
          {disabled
            ? `File successfully uploaded!`
            : `Drag and drop the file you wish to import, or click to browse.`}
        </HeadingSmallest>
      </span>
    </FileUploader>
  )
}

export const FileAdded = ({
  file,
  onRemove,
}: {
  file: File
  onRemove: (file: File) => void
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Paper style={{ marginLeft: '12px' }} />
        <HeadingSmallest>
          {file.name} - {file.size} bytes
        </HeadingSmallest>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <GreenCheckCircle style={{ height: '24px' }} />
        <CloseX style={{ height: '24px' }} onClick={() => onRemove(file)} />
      </div>
    </div>
  )
}
