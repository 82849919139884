import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import ContractsEmpty from '../assets/ContractsEmpty'
import Loading from '../assets/Loading'
import Button from '../components/Button'
import {
  Badge,
  ContractEdit,
  ContractModal,
  ContractModalType,
} from './ContractModal'
import {
  ApplicationCell,
  ArrowDownCell,
  OwnerCell,
  SortArrow,
  SubTable,
  SubTableCell,
  SubTableHead,
  SubTableHeading,
  SubTableRow,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TitleCell,
} from '../components/Table'
import {
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import theme from '../Theme'
import {
  currencyFormat,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { UserType } from '../Users'
import { PDFPreviewModal } from '../Overview/LatestInvoicesTable'
import useImpersonation from '../hooks/useImpersonation'
import {
  Application,
  dateFilter,
  FilterSection,
  filterStringIncludes,
  filterSubstring,
  matchesSpendAmountFilter,
  Pagination,
  TableFilters,
  TooManyFiltersTableBody,
} from '../Overview/ApplicationsTable'
import Dropdown from '../components/Dropdown'
import Paper from '../assets/Paper'
import Archive from '../assets/Archive'
import Pencil from '../assets/Pencil'
import IroncladIcon from '../assets/logos/ironcladIcon.png'

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'sticky',
  top: '77px',
  background: theme.color.backgroudDefault,
  zIndex: 5,
  alignItems: 'flex-end',
  width: '100%',
  padding: '16px 0',
})

type Contract = {
  id: number
  saas_id: number
  type: string[]
  name: string
  logo_url: string
  plan_name: string
  owner: string
  owner_hash: string
  procurement_owner: string
  profile_picture: string
  pricing_model: string
  frequency: number
  num_users: number
  billing_cycle: string
  contract_value: number
  issue_date: string
  start_date: string
  end_date: string
  paid_licenses: string
  archived_date: string
  has_preview: boolean
  receiver_email: string
  external_url: string
}

export type ContractList = { [saas_id: number]: Contract[] }

export type Contracts = {
  active_contracts: ContractList
  archived_contracts: ContractList
  num_active: number
  num_archived: number
}

type CustomInbox = {
  email: string
}

const HeadingWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '16px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  backgroundColor: theme.color.white,
  padding: '18px 24px',
})

const EmailWrapper = styled('span')({
  fontWeight: 'bold',
})

const ActiveContractsPage = () => {
  const [contracts, setContracts] = useState<Contracts | null>()
  const [contractEdit, setContractEdit] = useState<ContractEdit | null>(null)
  const [paidTools, setPaidTools] = useState<Application[] | null>()
  const [inboxes, setInboxes] = useState<CustomInbox[]>()
  const [showModal, setShowModal] = useState(false)
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    if (contracts) {
      return
    }
    const getContracts = async () => {
      const reqPath = addImpersonationParams(
        '/contracts',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        showToast(
          'Failed to fetch contracts. Please try again later.',
          ToastType.ERROR
        )
        setContracts(null)
        return
      }
      setContracts(resp)
    }
    getContracts()
  }, [contracts, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (paidTools) {
      return
    }
    const getPaidTools = async () => {
      const reqPath = addImpersonationParams(
        '/expenses/products',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        showToast(
          'Failed to fetch paid applications. Please try again later.',
          ToastType.ERROR
        )
        setPaidTools(null)
        return
      }
      setPaidTools(resp)
    }
    getPaidTools()
  }, [paidTools, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (inboxes) {
      return
    }
    const getInboxes = async () => {
      const reqPath = addImpersonationParams(
        '/custom_inbox',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        setInboxes([])
        return
      }
      setInboxes(resp)
    }
    getInboxes()
  }, [inboxes, reqOrgId, reqUserId, impersonate])

  if (contracts === undefined) {
    return <Loading large />
  }

  const onDismiss = () => {
    setShowModal(false)
  }

  const handleContractEdit = (contract_id: number) => {
    let contract = null
    for (const [, value] of Object.entries(contracts?.active_contracts || [])) {
      contract = value.find((c) => c.id === contract_id)
      if (contract) {
        break
      }
    }
    if (contract) {
      setContractEdit({
        contract_id: contract.id,
        application_name: contract.name,
        owner_email: atob(contract.owner_hash),
        plan_name: contract.plan_name,
        billing_cycle: contract.billing_cycle,
        contract_value: contract.contract_value,
        start_date: contract.start_date,
        end_date: contract.end_date,
        num_seats: Number(contract.paid_licenses),
        document_type: contract.type,
      })
      setShowModal(true)
    }
  }

  const contractEditCallback = (contract: ContractEdit) => {
    setContracts(null)
  }

  return (
    <>
      <ForwardInbox
        inboxes={inboxes}
        addContract={() => {
          setShowModal(true)
          setContractEdit(null)
        }}
      />
      <ActiveContracts
        onClick={() => setShowModal(true)}
        handleContractEdit={handleContractEdit}
        contractList={contracts?.active_contracts || []}
        paidTools={paidTools || []}
        numContracts={contracts?.num_active || 0}
      />
      {showModal && (
        <ContractModal
          modalType={contractEdit ? ContractModalType.EDIT : undefined}
          contractEdit={contractEdit}
          onDismiss={onDismiss}
          contractEditCallback={contractEditCallback}
        />
      )}
    </>
  )
}

const ForwardInbox = ({
  inboxes,
  addContract,
}: {
  inboxes: CustomInbox[] | undefined
  addContract: () => void
}) => (
  <HeadingWrapper>
    <HeadingMedium color="textDefault">Add Contract</HeadingMedium>
    {inboxes === undefined || inboxes.length === 0 ? (
      <HeadingSmallest>
        Please contact the Diminish team for your forwarding email inbox to send
        invoices and contracts to our platform. The files will be linked to
        applications we are tracking for you on our platform.
      </HeadingSmallest>
    ) : (
      <HeadingSmallest>
        Enhance your contract management. Whether through document upload or
        manual entry. Additionally, forward your invoices and contracts to
        <EmailWrapper> {inboxes[0].email}</EmailWrapper>. The files will be
        linked to applications we are tracking for you on our platform.
      </HeadingSmallest>
    )}
    <Button onClick={() => addContract()}>Add a Contract</Button>
  </HeadingWrapper>
)

const EmptyStateWrapper = styled('div')({
  borderTop: `1px solid ${theme.color.border}`,
  borderBottom: `1px solid ${theme.color.border}`,
  margin: '15px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  gap: '8px',
})

const ActiveContractEmptyState = ({ onClick }: { onClick: () => void }) => (
  <TableRow>
    <TableCell colSpan={15}>
      <EmptyStateWrapper>
        <ContractsEmpty />
        <HeadingSmall color="textDefault">
          This is where you see your Contracts
        </HeadingSmall>
        <Button style={{ marginTop: '16px' }} onClick={onClick}>
          Add Contracts
        </Button>
      </EmptyStateWrapper>
    </TableCell>
  </TableRow>
)

enum ContractFilter {
  ALL = 'All Contracts',
  MISSING = 'Missing Contracts',
  EXISTING = 'Existing Contracts',
}

const getAppOwners = (contracts: {
  [saas_id: number]: Contract[]
}): string[] => {
  const owners: string[] = []
  for (const cList of Object.values(contracts)) {
    const owner = cList[0]?.owner
    if (owner && !owners.includes(owner)) {
      owners.push(owner)
    }
  }
  return owners.sort()
}

const getProcurementOwners = (contracts: {
  [saas_id: number]: Contract[]
}): string[] => {
  const owners: string[] = []
  for (const cList of Object.values(contracts)) {
    const owner = cList[0]?.procurement_owner
    if (owner && !owners.includes(owner)) {
      owners.push(owner)
    }
  }
  return owners.sort()
}

const getDepartments = (apps: Application[] | undefined): string[] => {
  const departments: string[] = []
  if (!apps) return departments
  for (const app of apps) {
    if (app.department && !departments.includes(app.department)) {
      departments.push(app.department)
    }
  }
  return departments.sort()
}

const filterContracts = (
  contracts: { [saas_id: number]: Contract[] },
  paidTools: Application[],
  filter: ContractFilter,
  filters: TableFilters
): { [saas_id: number]: Contract[] } | undefined => {
  const displayContracts: { [saas_id: number]: Contract[] } = {}
  for (const [saas_id, cList] of Object.entries(contracts) || []) {
    if (filter === ContractFilter.MISSING) {
      if (paidTools.find((tool) => tool.id === parseInt(saas_id))) {
        continue
      }
      displayContracts[parseInt(saas_id)] = cList
      continue
    }
    for (const c of cList) {
      if (
        !filterSubstring(c.name, filters.appFilter) ||
        !filterStringIncludes(c.owner, filters.ownerFilter) ||
        !filterStringIncludes(
          c.procurement_owner,
          filters.procurementOwnerFilter
        ) ||
        !matchesSpendAmountFilter(
          c.contract_value,
          filters.contractValueFilter
        ) ||
        !dateFilter(c.start_date, filters.startDate) ||
        !dateFilter(c.end_date, filters.endDate)
      ) {
        continue
      }
      const saasIdNumber = parseInt(saas_id)
      if (!displayContracts[saasIdNumber]) {
        displayContracts[saasIdNumber] = []
      }
      displayContracts[saasIdNumber].push(c)
    }
  }
  return displayContracts
}

const pageSize = 10

export const ActiveContracts = ({
  contractList,
  paidTools,
  numContracts,
  onClick,
  handleContractEdit,
}: {
  contractList: { [saas_id: number]: Contract[] }
  paidTools: Application[]
  numContracts: number
  onClick: () => void
  handleContractEdit: (contract_id: number) => void
}) => {
  const [contracts, setContracts] = useState<{ [saas_id: number]: Contract[] }>(
    contractList
  )
  const [users, setUsers] = useState<UserType[] | undefined>(undefined)
  const [showPDFPreviewModal, setShowPDFPreviewModal] = useState(false)
  const [filter, setFilter] = useState<ContractFilter>(ContractFilter.EXISTING)
  const [tableFilters, setTableFilters] = useState<TableFilters>({
    appFilter: '',
    ownerFilter: [],
    procurementOwnerFilter: [],
    contractValueFilter: { type: undefined, amount: 0 },
    startDate: { type: undefined, date: '' },
    endDate: { type: undefined, date: '' },
  })
  const [invoiceURL, setInvoiceURL] = useState('')
  const [page, setPage] = useState(0)
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  const contractCount = {
    [ContractFilter.ALL]: numContracts,
    [ContractFilter.EXISTING]: numContracts,
    [ContractFilter.MISSING]: paidTools.reduce(
      (acc, tool) => acc + (contracts[tool.id] === undefined ? 1 : 0),
      0
    ),
  }

  useEffect(() => {
    if (!contractList || Object.keys(contractList).length === 0) {
      return
    }
    setContracts(contractList)
  }, [contractList])

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        '/user_names',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(
          'Failed to fetch users. Please try again later.',
          ToastType.ERROR
        )
        setUsers([])
      }
      setUsers(resp)
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  const handleArchive = async (contract_id: number) => {
    const contractArchivePath = addImpersonationParams(
      '/contracts/archive',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )
    const resp = await backendRequest(contractArchivePath, {
      method: 'POST',
      body: JSON.stringify({
        contract_id,
      }),
    })

    if (resp.error) {
      showToast(
        'Failed to archive contract. Please try again later.',
        ToastType.ERROR
      )
      return
    }
    showToast('Contract successfully archived', ToastType.SUCCESS)
    const updatedContracts = { ...contracts }
    for (const [key, value] of Object.entries(updatedContracts)) {
      updatedContracts[parseInt(key)] = value.filter(
        (contract) => contract.id !== contract_id
      )
    }
    setContracts(updatedContracts)
  }

  const handlePDFPreview = async (contract_id: number) => {
    setInvoiceURL('')
    setShowPDFPreviewModal(true)

    const reqPath = addImpersonationParams(
      `/contracts/preview/${contract_id}`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(reqPath)
    if (resp.error) {
      showToast('Failed to fetch contract preview', ToastType.ERROR)
      return
    }

    const { preview_url } = resp
    setInvoiceURL(preview_url)
  }

  const filteredContracts: { [saas_id: number]: Contract[] } | undefined =
    useMemo(() => {
      if (!contracts) {
        return undefined
      }
      return filterContracts(contracts, paidTools, filter, tableFilters)
    }, [contracts, tableFilters, filter, paidTools])

  const paginatedContracts = useMemo(() => {
    if (!filteredContracts) {
      return {}
    }
    return Object.fromEntries(
      Object.entries(filteredContracts).slice(
        page * pageSize,
        (page + 1) * pageSize
      )
    )
  }, [filteredContracts, page])

  const pageCount = useMemo(() => {
    if (!filteredContracts) {
      return 0
    }
    return Math.ceil(Object.keys(filteredContracts).length / pageSize)
  }, [filteredContracts])

  return (
    <>
      <ButtonWrapper>
        <HeadingMedium color="textDefault">
          {filter}
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {` (${contractCount[filter]})`}
          </span>
        </HeadingMedium>
        <Dropdown
          defaultValue={filter}
          options={[
            ContractFilter.ALL,
            ContractFilter.EXISTING,
            ContractFilter.MISSING,
          ]}
          onSelect={(selected: string) => {
            setFilter(selected as ContractFilter)
            setPage(0)
          }}
        />
      </ButtonWrapper>
      <FilterSection
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        departments={getDepartments(paidTools)}
        owners={getAppOwners(contracts)}
        procurementOwners={getProcurementOwners(contracts)}
      />
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
          maxHeight: 'calc(100vh - 272px)',
          zIndex: 4,
        }}
      >
        <TableWrapper cellSpacing={0}>
          <TableHead style={{ top: '0px', position: 'sticky', zIndex: 3 }}>
            <TableRow
              style={{
                position: 'sticky',
                top: '0px',
                background: theme.color.backgroudDefault,
              }}
            >
              <TableCell
                w="15px"
                style={{
                  background: theme.color.backgroudDefault,
                  position: 'sticky',
                  left: '0px',
                  zIndex: 4,
                }}
              ></TableCell>
              <TableCell
                mw="160px"
                style={{
                  boxShadow: `3px 0 6px -3px ${theme.color.border}`,
                  position: 'sticky',
                  background: theme.color.backgroudDefault,
                  zIndex: 4,
                  left: '45px',
                }}
              >
                Application
              </TableCell>
              <TableCell w="325px">Agreements & InfoSec</TableCell>
              <TableCell w="200px">App Owner</TableCell>
              <TableCell w="200px">Procurement Owner</TableCell>
              <TableCell w="150px">Contract Value</TableCell>
              <TableCell w="150px">Start Date</TableCell>
              <TableCell w="150px">End Date</TableCell>
              <TableCell
                w="100px"
                style={{
                  position: 'sticky',
                  right: '0',
                  backgroundColor: theme.color.backgroudDefault,
                  boxShadow: `-3px 0 6px -3px ${theme.color.border}`,
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(contracts).length > 0 &&
            Object.keys(filteredContracts || {}).length === 0 ? (
              <TooManyFiltersTableBody colSpan={15} />
            ) : Object.keys(paginatedContracts).length === 0 ? (
              <ActiveContractEmptyState onClick={onClick} />
            ) : (
              <ActiveContractRows
                allContracts={contracts}
                contracts={paginatedContracts}
                tools={paidTools}
                users={users || []}
                filter={filter}
                handleContractEdit={handleContractEdit}
                handleArchive={handleArchive}
                handlePDFPreview={handlePDFPreview}
              />
            )}
          </TableBody>
        </TableWrapper>
        {filter === ContractFilter.EXISTING && (
          <Pagination
            page={page}
            pageCount={pageCount}
            total={Object.keys(filteredContracts || {}).length}
            setPage={setPage}
          />
        )}
      </div>
      {showPDFPreviewModal !== false && (
        <PDFPreviewModal
          onDismiss={() => setShowPDFPreviewModal(false)}
          invoice_url={invoiceURL}
        />
      )}
      <Tooltip anchorSelect=".edit-icon" place="top" style={{ zIndex: 10 }}>
        Edit Contract
      </Tooltip>
      <Tooltip anchorSelect=".preview-icon" place="top" style={{ zIndex: 10 }}>
        Preview Contract
      </Tooltip>
      <Tooltip anchorSelect=".archive-icon" place="top" style={{ zIndex: 10 }}>
        Archive Contract
      </Tooltip>
      <Tooltip anchorSelect=".ironclad-icon" place="top" style={{ zIndex: 10 }}>
        View Contract in Ironclad
      </Tooltip>
    </>
  )
}

const ActiveContractRows = ({
  allContracts,
  contracts,
  tools,
  users,
  filter,
  handleContractEdit,
  handleArchive,
  handlePDFPreview,
}: {
  allContracts: { [saas_id: number]: Contract[] }
  contracts: { [saas_id: number]: Contract[] }
  tools: Application[]
  users: UserType[]
  filter: ContractFilter
  handleContractEdit: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
  handlePDFPreview: (contract_id: number) => void
}) => {
  if (Object.keys(contracts).length === 0 && tools.length === 0) {
    return <ActiveContractEmptyState onClick={() => {}} />
  }

  const existingTools: {
    [saas_id: number]: { app?: Application; appContracts?: Contract[] }
  } = {}
  for (const tool of tools) {
    existingTools[tool.id] = { app: tool }
  }
  for (const [key, value] of Object.entries(contracts)) {
    if (existingTools[parseInt(key)] === undefined) {
      existingTools[parseInt(key)] = { appContracts: value }
    } else {
      existingTools[parseInt(key)].appContracts = value
    }
  }

  if (filter === ContractFilter.EXISTING) {
    return Object.entries(existingTools).map(
      ([key, { app, appContracts }], i) =>
        !appContracts ? null : (
          <ActiveContractRow
            key={i}
            allContracts={allContracts}
            contracts={contracts[parseInt(key)] || []}
            app={app}
            users={users}
            handleContractEdit={handleContractEdit}
            handleArchive={handleArchive}
            handlePDFPreview={handlePDFPreview}
          />
        )
    )
  }
  const allTools: {
    [saas_id: number]: { app?: Application; appContracts?: Contract[] }
  } = {}
  for (const tool of tools) {
    allTools[tool.id] = { app: tool }
  }
  for (const [key, value] of Object.entries(allContracts)) {
    if (allTools[parseInt(key)] === undefined) {
      allTools[parseInt(key)] = { appContracts: value }
    } else {
      allTools[parseInt(key)].appContracts = value
    }
  }

  if (filter === ContractFilter.MISSING) {
    return Object.entries(allTools).map(([key, { app, appContracts }], i) =>
      appContracts ? null : (
        <ActiveContractRow
          key={i}
          allContracts={allContracts}
          contracts={appContracts || []}
          app={app}
          users={users}
          handleContractEdit={handleContractEdit}
          handleArchive={handleArchive}
          handlePDFPreview={handlePDFPreview}
        />
      )
    )
  }
  if (filter === ContractFilter.ALL) {
    return Object.entries(allTools).map(([key, { app, appContracts }], i) => (
      <ActiveContractRow
        key={i}
        allContracts={allContracts}
        contracts={appContracts || []}
        app={app}
        users={users}
        handleContractEdit={handleContractEdit}
        handleArchive={handleArchive}
        handlePDFPreview={handlePDFPreview}
      />
    ))
  }
}

const ActiveContractRow = ({
  allContracts,
  contracts,
  app,
  users,
  handleContractEdit,
  handleArchive,
  handlePDFPreview,
}: {
  allContracts: { [saas_id: number]: Contract[] }
  contracts: Contract[]
  app: Application | undefined
  users: UserType[]
  handleContractEdit: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
  handlePDFPreview: (contractID: number) => void
}) => {
  const [showDetails, setShowDetails] = useState(false)

  if (!app && !contracts.length) {
    return null
  }

  const saas_id = contracts.length ? contracts[0].saas_id : app!.id
  const contract = contracts ? contracts[0] : undefined
  const getAgreements = (contract: Contract) => {
    return contract.type.some((type) =>
      ['MSA/TOU', 'DPA', '(M)NDA', 'InfoSec'].includes(type)
    )
  }

  const hasDocType = (type: string) =>
    !contracts.every((c) => c.type && c.type.every((t) => t !== type))

  return (
    <>
      <TableRow alert={contracts.length === 0}>
        <ArrowDownCell
          onClick={() => setShowDetails(!showDetails)}
          open={showDetails}
        />
        <ApplicationCell
          id={saas_id}
          logo={app?.logo || contract?.logo_url}
          name={app?.name || contract?.name || ''}
          number={contracts.length || 0}
          error={
            allContracts[saas_id] === undefined ? 'Missing Contract' : undefined
          }
        />
        <TableCell>
          <Badge disabled={!hasDocType('MSA/TOU')}>MSA/TOU</Badge>
          <Badge disabled={!hasDocType('DPA')}>DPA</Badge>
          <Badge disabled={!hasDocType('(M)NDA')}>(M)NDA</Badge>
          <Badge disabled={!hasDocType('InfoSec')}>InfoSec</Badge>
        </TableCell>
        <OwnerCell
          appId={saas_id}
          options={users}
          defaulOption={app?.app_owner || contract?.owner || ''}
          ownerType="app"
        />
        <OwnerCell
          appId={saas_id}
          options={users}
          defaulOption={
            app?.procurement_owner || contract?.procurement_owner || ''
          }
          ownerType="procurement"
        />
        <TableCell>
          {contract?.contract_value === undefined
            ? '-'
            : `$${currencyFormat(contract.contract_value)}`}
        </TableCell>
        <TableCell w="150px">{contract?.start_date || '-'}</TableCell>
        <TableCell w="150px">{contract?.end_date || '-'}</TableCell>
        <TableCell
          mw="110px"
          style={{
            textAlign: 'center',
            position: 'sticky',
            right: '0',
            backgroundColor: theme.color.white,
            boxShadow: `-3px 0 6px -3px ${theme.color.border}`,
          }}
        >
          {contract ? (
            <Pencil
              className="edit-icon"
              onClick={() => handleContractEdit(contract?.id || 0)}
            />
          ) : null}
          {contract && contract.has_preview ? (
            <Paper
              style={{ marginLeft: '12px' }}
              className="preview-icon"
              onClick={() => handlePDFPreview(contract.id)}
            />
          ) : null}
          {contract ? (
            <Archive
              className="archive-icon"
              style={{ marginLeft: '12px' }}
              onClick={() => handleArchive(contract.id)}
            />
          ) : null}
        </TableCell>
      </TableRow>
      {showDetails && (
        <>
          <ContractMoreDetail
            app={app}
            pricing_model={contracts[0]?.pricing_model || '-'}
          />
          <ContractAgreements
            contracts={contracts.filter((c) => getAgreements(c))}
            handleContractEdit={handleContractEdit}
            handlePDFPreview={handlePDFPreview}
            handleArchive={handleArchive}
          />
          <ContractsAllPerTool
            contracts={contracts.filter((c) => !getAgreements(c))}
            handleContractEdit={handleContractEdit}
            handlePDFPreview={handlePDFPreview}
            handleArchive={handleArchive}
          />
        </>
      )}
    </>
  )
}

export const ContractMoreDetail = ({
  app,
  pricing_model,
}: {
  app?: Application
  pricing_model: string
}) => (
  <SubTable>
    <SubTableCell colSpan={15}>
      <table cellSpacing={0} style={{ width: '100%' }}>
        <tbody>
          <SubTableRow>
            <TitleCell>More Detail</TitleCell>
            <TableCell>
              <table cellSpacing={0} style={{ width: '100%' }}>
                <SubTableHead>
                  <SubTableRow>
                    <SubTableHeading>Department</SubTableHeading>
                    <SubTableHeading>Pricing Model</SubTableHeading>
                    <SubTableHeading>Users</SubTableHeading>
                    <SubTableHeading>Total Visits</SubTableHeading>
                    <SubTableHeading
                      style={{ width: '35px' }}
                    ></SubTableHeading>
                  </SubTableRow>
                </SubTableHead>
                <tbody>
                  <SubTableRow>
                    <TableCell>{app?.department || '-'}</TableCell>
                    <TableCell>{app?.pricing_model || pricing_model}</TableCell>
                    <TableCell>{app?.num_users || '-'}</TableCell>
                    <TableCell>{app?.num_visits || '-'}</TableCell>
                    <SubTableCell style={{ width: '35px' }}></SubTableCell>
                  </SubTableRow>
                </tbody>
              </table>
            </TableCell>
          </SubTableRow>
        </tbody>
      </table>
    </SubTableCell>
  </SubTable>
)

const ContractAgreements = ({
  contracts,
  handleContractEdit,
  handlePDFPreview,
  handleArchive,
}: {
  contracts: Contract[]
  handleContractEdit: (contract_id: number) => void
  handlePDFPreview: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
}) =>
  contracts &&
  contracts.length > 0 && (
    <SubTable>
      <SubTableCell colSpan={15}>
        <table cellSpacing={0} style={{ width: '100%' }}>
          <tbody>
            <SubTableRow>
              <TitleCell>
                Agreements & InfoSec
                <span
                  style={{ fontWeight: '400', fontSize: '14px' }}
                >{` (${contracts.length})`}</span>
              </TitleCell>
              <TableCell>
                <table cellSpacing={0} style={{ width: '100%' }}>
                  <SubTableHead>
                    <SubTableRow>
                      <SubTableHeading>Document</SubTableHeading>
                      <SubTableHeading>Signed Date</SubTableHeading>
                      <SubTableHeading
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100px',
                        }}
                      >
                        <span>Expiry Date</span>
                        <SortArrow />
                      </SubTableHeading>
                      <SubTableHeading>Receiver Email</SubTableHeading>
                      <SubTableHeading
                        style={{ width: '110px' }}
                      ></SubTableHeading>
                    </SubTableRow>
                  </SubTableHead>
                  <tbody>
                    {contracts &&
                      contracts.map((c, i) => (
                        <SubTableRow key={i}>
                          <TableCell>
                            {c.plan_name ? `${c.type} - ${c.plan_name}` : '-'}
                          </TableCell>
                          <TableCell>{c.start_date || '-'}</TableCell>
                          <TableCell>{c.end_date || '-'}</TableCell>
                          <TableCell>{c.receiver_email || '-'}</TableCell>
                          <TableCell
                            mw="110px"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              gap: '12px',
                            }}
                          >
                            {c ? (
                              <Pencil
                                className="edit-icon"
                                onClick={() => handleContractEdit(c.id)}
                              />
                            ) : null}
                            {c && c.has_preview ? (
                              <Paper
                                className="preview-icon"
                                onClick={() => handlePDFPreview(c.id)}
                              />
                            ) : null}
                            <Archive
                              className="archive-icon"
                              onClick={() => handleArchive(c.id)}
                            />
                          </TableCell>
                          <SubTableCell
                            style={{ width: '15px' }}
                          ></SubTableCell>
                        </SubTableRow>
                      ))}
                  </tbody>
                </table>
              </TableCell>
            </SubTableRow>
          </tbody>
        </table>
      </SubTableCell>
    </SubTable>
  )
const ContractsAllPerTool = ({
  contracts,
  handleContractEdit,
  handlePDFPreview,
  handleArchive,
}: {
  contracts: Contract[]
  handleContractEdit: (contract_id: number) => void
  handlePDFPreview: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
}) =>
  contracts &&
  contracts.length > 0 && (
    <SubTable>
      <SubTableCell colSpan={15}>
        <table cellSpacing={0} style={{ width: '100%' }}>
          <tbody>
            <SubTableRow>
              <TitleCell>
                All Contracts
                <span
                  style={{ fontWeight: '400', fontSize: '14px' }}
                >{` (${contracts.length})`}</span>
              </TitleCell>
              <TableCell>
                <table cellSpacing={0} style={{ width: '100%' }}>
                  <SubTableHead>
                    <SubTableRow>
                      <SubTableHeading>Plan</SubTableHeading>
                      <SubTableHeading>Paid Licenses</SubTableHeading>
                      <SubTableHeading>Contract Value</SubTableHeading>
                      <SubTableHeading>Start Date</SubTableHeading>
                      <SubTableHeading
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100px',
                        }}
                      >
                        <span>End Date</span>
                        <SortArrow />
                      </SubTableHeading>
                      <SubTableHeading>Receiver Email</SubTableHeading>
                      <SubTableHeading
                        style={{ width: '110px' }}
                      ></SubTableHeading>
                    </SubTableRow>
                  </SubTableHead>
                  <tbody>
                    {contracts &&
                      contracts.map((c, i) => (
                        <SubTableRow key={i}>
                          <TableCell>{c.plan_name || '-'}</TableCell>
                          <TableCell>{c.paid_licenses || 'N/A'}</TableCell>
                          <TableCell>
                            {c.contract_value === undefined
                              ? '-'
                              : `$${currencyFormat(c.contract_value)}`}
                          </TableCell>
                          <TableCell>{c.start_date || '-'}</TableCell>
                          <TableCell>{c.end_date || '-'}</TableCell>
                          <TableCell>{c.receiver_email || '-'}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                maxWidth: '110px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '12px',
                                height: '100%',
                              }}
                            >
                              {c ? (
                                <Pencil
                                  className="edit-icon"
                                  onClick={() => handleContractEdit(c.id)}
                                />
                              ) : null}
                              {c && c.has_preview ? (
                                <Paper
                                  className="preview-icon"
                                  onClick={() => handlePDFPreview(c.id)}
                                />
                              ) : null}
                              {c && c.external_url !== '' ? (
                                <img
                                  alt="Ironclad Icon"
                                  src={IroncladIcon}
                                  style={{ width: '16px', marginBottom: '4px' }}
                                  className="ironclad-icon"
                                  onClick={() =>
                                    window.open(c.external_url, '_blank')
                                  }
                                />
                              ) : null}
                              <Archive
                                className="archive-icon"
                                onClick={() => handleArchive(c.id)}
                              />
                            </div>
                          </TableCell>
                          <SubTableCell
                            style={{ width: '15px' }}
                          ></SubTableCell>
                        </SubTableRow>
                      ))}
                  </tbody>
                </table>
              </TableCell>
            </SubTableRow>
          </tbody>
        </table>
      </SubTableCell>
    </SubTable>
  )

export default ActiveContractsPage
