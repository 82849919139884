import { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import { HeadingSmallest } from '../../components/Text'
import Divider from '../../components/Divider'
import { TextField } from '../../components/InputFields'
import Button from '../../components/Button'
import {
  LoginCard,
  PageWrapper,
  PurpleLink,
  WelcomeText,
} from '../../components/LoginComponents'
import { useFlags } from 'launchdarkly-react-client-sdk'

export default function Login() {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const { login, loading, error } = useAuth()

  const { oktaSsoAll } = useFlags()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    login({
      email,
      password,
    })
  }

  useEffect(() => {
    if (error) {
      setPassword('')
    }
  }, [error])

  const shouldDisableSubmit = () => {
    return !email || !password || loading
  }

  return (
    <PageWrapper>
      <LoginCard>
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <WelcomeText>Welcome to Diminish</WelcomeText>
          <Divider style={{ margin: '16px 0' }} />
          <HeadingSmallest color="textSubdued" style={{ marginBottom: '24px' }}>
            Please log in to your account
          </HeadingSmallest>
          <TextField
            label="Email"
            type="text"
            name="email"
            placeholder="Enter your email"
            errorMsg={error ? 'Please enter a valid email address' : ''}
            onChange={(value) => setEmail(value)}
            marginBottom="32px"
          />
          <TextField
            label={'Password'}
            type="password"
            name="password"
            value={password}
            placeholder="Enter your password"
            onChange={(value) => setPassword(value)}
            errorMsg={error ? 'Please enter a valid password' : ''}
          />
          <PurpleLink href="/forget_password">Forgot password?</PurpleLink>
          <Button disabled={shouldDisableSubmit()} type="submit">
            Log In
          </Button>
        </form>
        {oktaSsoAll && (
          <>
            <Divider style={{ margin: '24px 0' }} />
            <Button onClick={() => (window.location.href = '/login/okta')}>
              Okta SSO Login
            </Button>
          </>
        )}
      </LoginCard>
    </PageWrapper>
  )
}
