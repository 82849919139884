import React, { useCallback, useEffect, useState } from 'react'
import { AccessLevel, UserType } from '.'
import RadioButtonChecked from '../assets/RadioButtonChecked'
import { LogoWithFallback } from '../components/Logo'
import {
  AccessLevelCell,
  ActionsCell,
  EmptyTableBody,
  SortOrder,
  SortableHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  UserCell,
} from '../components/Table'
import theme from '../Theme'
import {
  backendRequest,
  getDateDiff,
  getThisMonthShort,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { Roles } from '../api/user'
import useAuth from '../hooks/useAuth'
import useImpersonation from '../hooks/useImpersonation'
import { useFlags } from 'launchdarkly-react-client-sdk'

enum TableSort {
  USER = 'user',
  ACCESS_LEVEL = 'access_level',
  STATUS = 'status',
  APPLICATIONS = 'applications',
  VISITS = 'visits',
}

export const UserTable = ({
  userList,
  access_level,
  roles,
  handleRoleChange,
  handleUserArchive,
}: {
  userList: UserType[]
  access_level: AccessLevel
  roles: Roles | undefined
  handleRoleChange: (modifiedUser: UserType) => void
  handleUserArchive: (modifiedUser: UserType) => void
}) => {
  const { user, permissions } = useAuth()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  const { showHris } = useFlags()

  const [users, setUsers] = useState<UserType[]>(userList)
  const [sortAttr, setSortAttr] = useState<TableSort>(TableSort.USER)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC)

  const sort = useCallback(() => {
    if (!users) {
      return []
    }
    const sorted = [...users].reverse()
    switch (sortAttr) {
      case TableSort.ACCESS_LEVEL:
        sorted.sort((a, b) => a.access_level.localeCompare(b.access_level))
        break
      case TableSort.STATUS:
        sorted.sort((a, b) => a.last_visit.localeCompare(b.last_visit))
        break
      case TableSort.APPLICATIONS:
        sorted.sort((a, b) => a.app_urls.length - b.app_urls.length)
        break
      case TableSort.VISITS:
        sorted.sort((a, b) => a.num_visits - b.num_visits)
        break
      case TableSort.USER:
      default:
        sorted.sort((a, b) => a.email.localeCompare(b.email))
    }
    sortOrder === SortOrder.DESC && sorted.reverse()
    return sorted
  }, [sortAttr, sortOrder, users])

  const inactiveDate = (last_visit: string) => {
    const lastDate = new Date(last_visit)
    if (getDateDiff(new Date(), lastDate) > 7) {
      return last_visit
    }
    return ''
  }
  const Active = 'Active'
  const Inactive = 'Inactive'
  const activeState = (last_visit: string) => {
    if (last_visit.length === 0 || inactiveDate(last_visit).length > 0) {
      return Inactive
    }
    return Active
  }

  const archiveUser = async (user_hash: string) => {
    const reqPath = addImpersonationParams(
      `/user/archive`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(reqPath, {
      method: 'POST',
      body: JSON.stringify({ user_hash }),
    })
    if (resp.error) {
      showToast(`Failed to archive user`, ToastType.ERROR)
    }
    showToast(`User successfully archived`, ToastType.SUCCESS)
    handleUserArchive(users.find((u) => u.hash === user_hash) as UserType)
  }

  const userActions = (userRow: UserType) => {
    let actions: { [s: string]: () => void } = {
      'Copy email': () => {
        navigator.clipboard.writeText(userRow.email)
      },
    }
    if (!user || !permissions) {
      return actions
    }
    if (
      user.hash !== userRow.hash &&
      permissions.edit_managers_admins &&
      permissions.edit_members
    ) {
      actions = {
        ...actions,
        'Archive user': () => {
          archiveUser(userRow.hash)
        },
      }
    }

    return actions
  }

  useEffect(() => {
    setUsers(sort())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAttr, sortOrder])

  const onSortChange = (selected: TableSort) => {
    if (!users) return
    if (sortAttr === selected) {
      setSortOrder(sortOrder * -1)
    } else {
      setSortOrder(SortOrder.ASC)
      setSortAttr(selected)
    }
  }

  const headerSize =
    users.length > 0 && users[0].access_level === 'Member' ? '190px' : '169px'

  return (
    <TableWrapper cellSpacing={0}>
      <TableHead>
        <TableRow
          style={{
            position: 'sticky',
            top: headerSize,
            background: theme.color.backgroudDefault,
            zIndex: 3,
          }}
        >
          <SortableHeader
            isSorted={sortAttr === TableSort.USER}
            sortOrder={sortOrder}
            onClick={() => onSortChange(TableSort.USER)}
          >
            User
          </SortableHeader>
          {showHris ? (
            <>
              <TableCell>Title</TableCell>
              <TableCell>Department</TableCell>
            </>
          ) : (
            <></>
          )}
          <SortableHeader
            isSorted={sortAttr === TableSort.ACCESS_LEVEL}
            sortOrder={sortOrder}
            onClick={() => onSortChange(TableSort.ACCESS_LEVEL)}
          >
            Access level
          </SortableHeader>
          <SortableHeader
            isSorted={sortAttr === TableSort.STATUS}
            sortOrder={sortOrder}
            onClick={() => onSortChange(TableSort.STATUS)}
          >
            User status
          </SortableHeader>
          <SortableHeader
            isSorted={sortAttr === TableSort.APPLICATIONS}
            sortOrder={sortOrder}
            onClick={() => onSortChange(TableSort.APPLICATIONS)}
          >
            Applications
          </SortableHeader>
          <SortableHeader
            isSorted={sortAttr === TableSort.VISITS}
            sortOrder={sortOrder}
            onClick={() => onSortChange(TableSort.VISITS)}
          >
            Visits ({getThisMonthShort()})
          </SortableHeader>
          <TableCell w="50px" rightAlign>
            Action
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.length === 0 && <EmptyTableBody colSpan={6} />}
        {users.map((user, i) => (
          <TableRow key={i} alert={activeState(user.last_visit) === Inactive}>
            <UserCell
              hash={user.hash}
              first_name={user.first_name}
              last_name={user.last_name}
              email={user.email}
              profile_picture={user.profile_picture}
            />
            {showHris ? (
              <>
                <TableCell>{user.title || '-'}</TableCell>
                <TableCell>{user.department || '-'}</TableCell>
              </>
            ) : (
              <></>
            )}
            <AccessLevelCell
              isAdminTable={user.access_level !== 'Member'}
              roles={roles}
              modifiedUser={user}
              handleRoleChange={handleRoleChange}
            />
            <TableCell>
              <UserStatus
                last_visit={user.last_visit}
                isActive={activeState(user.last_visit) === Active}
              />
            </TableCell>
            <TableCell>
              {user.app_urls ? user.app_urls.length : 0}
              <Applist app_urls={user.app_urls} />
            </TableCell>
            <TableCell>{user.num_visits}</TableCell>
            <ActionsCell options={userActions(user)} />
          </TableRow>
        ))}
      </TableBody>
    </TableWrapper>
  )
}

const Applist = ({ app_urls }: { app_urls: string[] }) => {
  const maxLogos = 4
  const displayLogos = app_urls.slice(0, maxLogos)
  return (
    <div
      style={{
        display: 'inline-flex',
        position: 'relative',
        top: '5px',
        left: '4px',
      }}
    >
      {displayLogos.map((a, i) => (
        <div
          key={i}
          style={{
            border: `1px solid ${theme.color.border}`,
            backgroundColor: 'white',
            borderRadius: '4px',
            position: 'relative',
            left: -7 * i + 'px',
            padding: '2px',
            width: '22px',
            height: '22px',
          }}
        >
          <LogoWithFallback
            alt={`apps-used-${i}`}
            src={a}
            style={{ width: '22px', zIndex: i }}
          />
        </div>
      ))}
      {app_urls.length > maxLogos && (
        <div
          style={{
            border: `1px solid ${theme.color.border}`,
            backgroundColor: 'white',
            borderRadius: '3px',
            position: 'relative',
            left: -7 * maxLogos + 'px',
            width: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span>+{app_urls.length - 4}</span>
        </div>
      )}
    </div>
  )
}

const UserStatus = ({
  last_visit,
  isActive,
}: {
  last_visit: string
  isActive: boolean
}) => {
  const formattedDate = new Date(last_visit).toLocaleDateString(
    navigator.language,
    { year: 'numeric', month: 'numeric', day: 'numeric' }
  )
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      {isActive ? (
        <>
          <RadioButtonChecked
            large
            style={{
              color: theme.color.textSuccess,
              position: 'relative',
              top: '2px',
            }}
          />
          <>Active</>
        </>
      ) : (
        <>
          <RadioButtonChecked
            large
            style={{
              color: theme.color.textError,
              position: 'relative',
              top: '2px',
            }}
          />
          <span>
            Inactive
            {last_visit.length > 0 ? (
              <span
                style={{
                  color: theme.color.textSubdued,
                  display: 'block',
                }}
              >
                Since {formattedDate}
              </span>
            ) : (
              <></>
            )}
          </span>
        </>
      )}
    </div>
  )
}
