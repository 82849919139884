import { useEffect, useState } from 'react'
import { HeadingLarge, HeadingMedium, HelperText } from '../../components/Text'
import Divider from '../../components/Divider'
import { HeadingSmall } from '../../components/Text'
import Loading from '../../assets/Loading'
import {
  currencyFormat,
  getMonthShort,
  getPastNMonths,
  backendRequest,
  addImpersonationParams,
} from '../../utils/utils'
import { ToastType, showToast } from '../../utils/toastify'
import { Application } from '../../Overview/ApplicationsTable'
import styled from 'styled-components'
import theme from '../../Theme'
import Dropdown from '../../components/Dropdown'
import {
  SavingsOpportunitiesTable,
  SavingsOpportunitiesType,
  UpcomingChargesTable,
  UserActivityTable,
  UserActivityType,
} from './ReportTables'
import useImpersonation from '../../hooks/useImpersonation'
import { PurpleLink } from '../../components/LoginComponents'
import { GhostButton } from '../../components/Button'
import DownloadIcon from '../../assets/Download'

const PurpleText = styled('span')({
  color: theme.color.textPurple,
})

const Counter = styled('span')({
  fontSize: '14px',
  fontWeight: 400,
})

type SpendComparsionType = {
  given_month: number
  prev_month: number
  two_months_ago: number
  average: number
}
type UpcomingChargesType = {
  upcoming_charges: Application[]
}

export default function ReportPage() {
  const [monthsAgo, setMonthsAgo] = useState(0)
  const [spendComparison, setSpendComparison] = useState<
    SpendComparsionType | null | undefined
  >()
  const [userActivity, setUserActivity] = useState<
    UserActivityType | null | undefined
  >()
  const [upcomingCharges, setUpcomingCharges] = useState<
    UpcomingChargesType | null | undefined
  >()
  const [savingsOpportunities, setSavingsOpportunities] = useState<
    SavingsOpportunitiesType | null | undefined
  >()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    if (spendComparison) {
      return
    }
    const getSpendComparison = async () => {
      const reqPath = addImpersonationParams(
        `/report/spend_comparison?monthsAgo=${monthsAgo}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        if (!resp.error.includes('integration not found')) {
          showToast(`Failed to fetch spending data`, ToastType.ERROR)
        }
        setSpendComparison(null)
        return
      }
      setSpendComparison(resp)
    }
    getSpendComparison()
  }, [spendComparison, monthsAgo, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (userActivity) {
      return
    }
    const getUserActivity = async () => {
      const reqPath = addImpersonationParams(
        `/report/user_activity`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        setUserActivity(null)
        return
      }
      setUserActivity(resp)
    }
    getUserActivity()
  }, [userActivity, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (upcomingCharges) {
      return
    }
    const getUpcomingCharges = async () => {
      const reqPath = addImpersonationParams(
        `/report/upcoming_charges`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        if (!resp.error.includes('integration not found')) {
          showToast(`Failed to fetch upcoming charges`, ToastType.ERROR)
        }
        setUpcomingCharges(null)
        return
      }
      setUpcomingCharges(resp)
    }
    getUpcomingCharges()
  }, [upcomingCharges, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (savingsOpportunities) {
      return
    }
    const getSavingsOpportunities = async () => {
      const reqPath = addImpersonationParams(
        `/report/savings_opportunities`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch savings opportunities`, ToastType.ERROR)
        setSavingsOpportunities(null)
        return
      }
      setSavingsOpportunities(resp)
    }
    getSavingsOpportunities()
  }, [savingsOpportunities, monthsAgo, reqOrgId, reqUserId, impersonate])

  const downloadRecommendations = () => {
    if (!savingsOpportunities) return
    const csv = savingsOpportunities.recommendations.map((ops) => {
      return `"${ops.app.name}","${ops.title}","${ops.markdown_description}","${ops.recommendation_type}","${ops.recommendation_status}","$${currencyFormat(ops.savings_amount_high)}","$${currencyFormat(ops.savings_amount_low)}","${getMonthShort(ops.report_month)}","${ops.rejection_reason}","${ops.instruction_url}"`
    })
    csv.unshift(
      [
        'Application',
        'Title',
        'Description',
        'Recommendation Type',
        'Recommendation Status',
        'Savings Amount High',
        'Savings Amount Low',
        'Report Month',
        'Rejection Reason',
        'Instruction URL',
      ].join(',')
    )
    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-recommendations-${new Date().toISOString()}.csv`
    a.click()
  }

  const monthsDropdown = getPastNMonths(6)
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <HeadingLarge style={{ margin: '16px 0' }} color="textDefault">
          Spending Overview
        </HeadingLarge>
        <Dropdown
          defaultValue={monthsDropdown[0]}
          marginBottom="24px"
          style={{ width: '200px' }}
          options={monthsDropdown}
          onSelect={function (selected: string): void {
            setSpendComparison(undefined)
            setSavingsOpportunities(undefined)
            setMonthsAgo(monthsDropdown.indexOf(selected))
          }}
        />
      </div>
      <SpendComparsion
        givenMonth={getMonthShort(monthsDropdown[monthsAgo])}
        prevMonth={getMonthShort(getPastNMonths(24)[monthsAgo + 1])}
        twoMonthsAgo={getMonthShort(getPastNMonths(24)[monthsAgo + 2])}
        sixMonthsAgo={getMonthShort(getPastNMonths(24)[monthsAgo + 6])}
        spendComparison={spendComparison}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <HeadingMedium style={{ margin: '0' }} color="textDefault">
            SaaS Usage Insights
            <Counter>
              {savingsOpportunities &&
                savingsOpportunities.recommendations.length > 0 &&
                ` (${savingsOpportunities.recommendations.length})`}
            </Counter>
          </HeadingMedium>
          <HelperText>
            Diminish has identified SaaS insights by analyzing your usage,
            spend, and contract data. This list of insights will be updated
            regularly as you continue to use the platform.{' '}
            <PurpleLink
              target="_blank"
              rel="noreferrer"
              href="https://lootdiscount.notion.site/9fa8dc34ad76424f88e30db2fcb9932f?v=0f0e7d5738344335956cc16192a089f0"
            >
              Click here to see instructions
            </PurpleLink>{' '}
            on how to implement these insights.
          </HelperText>
        </div>
        <GhostButton
          onClick={downloadRecommendations}
          style={{ padding: '12px' }}
        >
          <DownloadIcon />
        </GhostButton>
      </div>

      <SavingsOpportunitiesTable savingsOpportunities={savingsOpportunities} />

      <UserActivityTable userActivity={userActivity} />

      <UpcomingChargesTable upcomingCharges={upcomingCharges} />
    </>
  )
}

const CardWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '8px',
  backgroundColor: theme.color.white,
  padding: '16px 24px',
  marginBottom: '33px',
  flexGrow: 1,
})

const CardSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  marginRight: '24px',
})

export const SpendComparsion = ({
  spendComparison,
  givenMonth,
  prevMonth,
  twoMonthsAgo,
  sixMonthsAgo,
}: {
  spendComparison: SpendComparsionType | undefined | null
  givenMonth: string
  prevMonth: string
  twoMonthsAgo: string
  sixMonthsAgo: string
}) => {
  let errorMsg = ''
  if (spendComparison === null) {
    spendComparison = {
      given_month: 0,
      prev_month: 0,
      two_months_ago: 0,
      average: 0,
    }
    errorMsg =
      'We were unable to load your financial data. Please try again later.'
  }
  return (
    <>
      {spendComparison ? (
        <>
          {errorMsg && (
            <HelperText style={{ color: theme.color.errorRed }}>
              {errorMsg}
            </HelperText>
          )}
          <CardWrapper>
            <CardSection>
              <HeadingSmall>{givenMonth}</HeadingSmall>
              <HeadingLarge color="textDefault">
                ${currencyFormat(spendComparison.given_month)}
              </HeadingLarge>
            </CardSection>
            <Divider vertical style={{ height: '64px', margin: '0 40px' }} />
            <CardSection>
              <HeadingSmall>{prevMonth}</HeadingSmall>
              <HeadingLarge color="textDefault">
                ${currencyFormat(spendComparison.prev_month)}
              </HeadingLarge>
            </CardSection>
            <Divider vertical style={{ height: '64px', margin: '0 40px' }} />
            <CardSection>
              <HeadingSmall>{twoMonthsAgo}</HeadingSmall>
              <HeadingLarge color="textDefault">
                ${currencyFormat(spendComparison.two_months_ago)}
              </HeadingLarge>
            </CardSection>
            <Divider vertical style={{ height: '64px', margin: '0 40px' }} />
            <CardSection>
              <HeadingSmall>
                6-month Average{' '}
                <span style={{ fontWeight: '400', fontSize: '14px' }}>
                  ({sixMonthsAgo} - {givenMonth})
                </span>
              </HeadingSmall>
              <HeadingLarge color="textDefault">
                <PurpleText>
                  ${currencyFormat(spendComparison.average)}
                </PurpleText>
              </HeadingLarge>
            </CardSection>
          </CardWrapper>
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}
