import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const Pencil = ({
  style,
  onClick,
  className,
}: {
  style?: CSSProperties
  onClick?: () => void
  className?: string
}) => (
  <SVGWrapper style={style} onClick={onClick} className={className}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16536 15.833H5.08036L13.9956 6.91801L13.0804 6.0028L4.16536 14.918V15.833ZM3.33203 16.6663V14.567L14.316 3.57176C14.4014 3.49565 14.4956 3.43683 14.5987 3.3953C14.7019 3.35377 14.8095 3.33301 14.9214 3.33301C15.0333 3.33301 15.1418 3.35065 15.2468 3.38592C15.3517 3.4212 15.4485 3.48474 15.5372 3.57655L16.4266 4.47092C16.5184 4.55954 16.5812 4.65676 16.6149 4.76259C16.6486 4.86856 16.6654 4.97447 16.6654 5.0803C16.6654 5.19322 16.6463 5.30127 16.6083 5.40447C16.5702 5.50752 16.5097 5.60176 16.4266 5.68717L5.43141 16.6663H3.33203ZM13.5299 6.46842L13.0804 6.0028L13.9956 6.91801L13.5299 6.46842Z"
        fill="black"
      />
    </svg>
  </SVGWrapper>
)
export default Pencil
