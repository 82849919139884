import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const Archive = ({
  style,
  onClick,
  className,
}: {
  style?: CSSProperties
  onClick?: () => void
  className?: string
}) => (
  <SVGWrapper style={style} onClick={onClick} className={className}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42182 17.9163C4.00627 17.9163 3.65127 17.7692 3.35682 17.4749C3.06252 17.1804 2.91536 16.8254 2.91536 16.4099V7.07822C2.67078 6.95211 2.47071 6.77287 2.31516 6.54051C2.15974 6.30815 2.08203 6.04023 2.08203 5.73676V3.58947C2.08203 3.17391 2.22918 2.81891 2.52349 2.52447C2.81793 2.23016 3.17293 2.08301 3.58849 2.08301H16.4089C16.8245 2.08301 17.1795 2.23016 17.4739 2.52447C17.7682 2.81891 17.9154 3.17391 17.9154 3.58947V5.73676C17.9154 6.04023 17.8377 6.30815 17.6822 6.54051C17.5267 6.77287 17.3266 6.95211 17.082 7.07822V16.4099C17.082 16.8254 16.9349 17.1804 16.6406 17.4749C16.3461 17.7692 15.9911 17.9163 15.5756 17.9163H4.42182ZM4.16536 7.24322V16.3699C4.16536 16.4553 4.19606 16.5261 4.25745 16.5822C4.31898 16.6383 4.39516 16.6663 4.48599 16.6663H15.5756C15.6504 16.6663 15.7119 16.6423 15.7599 16.5943C15.808 16.5462 15.832 16.4847 15.832 16.4099V7.24322H4.16536ZM3.58849 5.99322H16.4089C16.4838 5.99322 16.5452 5.96919 16.5933 5.92113C16.6413 5.87308 16.6654 5.81162 16.6654 5.73676V3.58947C16.6654 3.5146 16.6413 3.45315 16.5933 3.40509C16.5452 3.35704 16.4838 3.33301 16.4089 3.33301H3.58849C3.51363 3.33301 3.45217 3.35704 3.40411 3.40509C3.35606 3.45315 3.33203 3.5146 3.33203 3.58947V5.73676C3.33203 5.81162 3.35606 5.87308 3.40411 5.92113C3.45217 5.96919 3.51363 5.99322 3.58849 5.99322ZM7.65891 11.1936H12.3385V9.99967H7.65891V11.1936Z"
        fill="#3A393B"
      />
    </svg>
  </SVGWrapper>
)
export default Archive
