import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const AccountBox = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        fill="#fff"
        d="M5 18.05q1.35-1.325 3.138-2.087Q9.925 15.2 12 15.2t3.863.763q1.787.762 3.137 2.087V5.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2Zm7-5q1.35 0 2.3-.95.95-.95.95-2.3 0-1.375-.95-2.313-.95-.937-2.3-.937-1.35 0-2.3.937-.95.938-.95 2.313 0 1.35.95 2.3.95.95 2.3.95ZM5.3 20.5q-.75 0-1.275-.525Q3.5 19.45 3.5 18.7V5.3q0-.75.525-1.275Q4.55 3.5 5.3 3.5h13.4q.75 0 1.275.525.525.525.525 1.275v13.4q0 .75-.525 1.275-.525.525-1.275.525ZM6.45 19h11.1v-.2q-1.175-1.075-2.587-1.588Q13.55 16.7 12 16.7q-1.525 0-2.95.512-1.425.513-2.6 1.563V19ZM12 11.55q-.725 0-1.238-.525-.512-.525-.512-1.225 0-.725.512-1.238.513-.512 1.238-.512t1.238.512q.512.513.512 1.238 0 .7-.512 1.225-.513.525-1.238.525Zm0-.025Z"
      />
    </svg>
  </SVGWrapper>
)
export default AccountBox
