import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NineDots from '../assets/NineDots'
import GroupPeople from '../assets/GroupPeople'
import ArrowDropDown from '../assets/ArrowDropDown'
import theme from '../Theme'
import { currencyFormat, getLastMonth, getThisMonthShort } from '../utils/utils'
import Divider from './Divider'
import { HeadingLarge, HeadingSmall, textStyle } from './Text'
import { IconWrapper } from './Profile'

const MainCardWrapper = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 29px;
  flex-shrink: 0;
  width: 420px;
  background: ${theme.color.white};
  border: 1px solid ${theme.color.backgroundPurple};
  box-shadow: inset 8px 0px 0px ${theme.color.backgroundPurple};
  border-radius: 8px;
`

const MonthlySpend = styled('p')(textStyle, () => ({
  fontSize: '49px',
  alignSelf: 'center',
  color: theme.color.textPurple,
}))

export const MainCard = ({
  getExpenseSummary,
}: {
  getExpenseSummary: () => Promise<any>
}) => {
  const [expenses, setExpenses] = useState({ prev_spend: 0, annual_spend: 0 })

  useEffect(() => {
    const getExpenses = async () => {
      const expenseSummary = await getExpenseSummary()
      if (expenseSummary.error) {
        // todo: better handle this error
        return
      }
      setExpenses(expenseSummary)
    }
    getExpenses()
  }, [getExpenseSummary])

  return (
    // TODO: add empty state
    <MainCardWrapper>
      <div>
        <HeadingLarge color="textSubdued" style={{ marginBottom: '0px' }}>
          Last Month's SaaS Spend <br />
        </HeadingLarge>
        <HeadingSmall inline style={{ fontSize: '16px' }}>
          ({getLastMonth()})
        </HeadingSmall>
      </div>
      <MonthlySpend>${currencyFormat(expenses.prev_spend)}</MonthlySpend>
      <Divider />
      <div>
        <HeadingSmall inline>Annual Spend:</HeadingSmall>
        <HeadingSmall
          inline
          color={'textDefault'}
          style={{ marginLeft: '15px' }}
        >
          ${currencyFormat(expenses.annual_spend)}
        </HeadingSmall>
      </div>
    </MainCardWrapper>
  )
}

const TotalSavingsWrapper = styled('div')({
  borderRadius: '8px',
  border: `1px solid ${theme.color.border}`,
  backgroundColor: theme.color.white,
  display: 'flex',
  alignItems: 'center',
  gap: '44px',
  padding: '24px',
  height: '75px',
})

const MonthComparison = styled('span')({
  color: theme.color.textSubdued,
  fontWeight: 500,
  fontSize: '14px',
})

const ArrowUp = () => (
  <ArrowDropDown
    style={{ color: theme.color.successGreen, transform: 'rotate(180deg)' }}
  />
)

const ArrowDown = () => (
  <ArrowDropDown style={{ color: theme.color.errorRed }} />
)

const PerformanceArrow = ({ indicator }: { indicator: number }) =>
  indicator < 0 ? <ArrowDown /> : <ArrowUp />

type UsageTotals = {
  total_apps: number
  total_users: number
  prev_total_apps: number
  prev_total_users: number
}

export const TotalSavingsCard = ({
  getUsageTotals,
}: {
  getUsageTotals: () => Promise<UsageTotals>
}) => {
  // TODO: replace icons

  const [usage, setUsage] = useState<UsageTotals>({
    total_apps: 0,
    total_users: 0,
    prev_total_apps: 0,
    prev_total_users: 0,
  })
  useEffect(() => {
    const getUsage = async () => {
      setUsage(await getUsageTotals())
    }
    getUsage()
  }, [getUsageTotals])

  const curMonth = getThisMonthShort().split(' ')[0]
  return (
    <TotalSavingsWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          flexGrow: 1,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '15px' }}>
            <IconWrapper Icon={NineDots} />
            <HeadingSmall>Total Applications</HeadingSmall>
          </div>
          <div style={{ display: 'flex' }}>
            <HeadingSmall color="textDefault" style={{ textAlign: 'right' }}>
              {usage.total_apps}{' '}
              <MonthComparison>
                ({usage.total_apps - usage.prev_total_apps > 0 && '+'}
                {usage.total_apps - usage.prev_total_apps} in {curMonth})
              </MonthComparison>
            </HeadingSmall>
            <PerformanceArrow
              indicator={usage.total_apps - usage.prev_total_apps}
            />
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '15px' }}>
            <IconWrapper Icon={GroupPeople} />

            <HeadingSmall>Active Users</HeadingSmall>
          </div>
          <div style={{ display: 'flex' }}>
            <HeadingSmall color="textDefault">
              {usage.total_users}{' '}
              <MonthComparison>
                ({usage.total_users - usage.prev_total_users > 0 && '+'}
                {usage.total_users - usage.prev_total_users} in {curMonth})
              </MonthComparison>
            </HeadingSmall>
            <PerformanceArrow
              indicator={usage.total_users - usage.prev_total_users}
            />
          </div>
        </div>
      </div>
    </TotalSavingsWrapper>
  )
}
