import styled, { CSSProperties } from 'styled-components'
import theme from '../Theme'

const Divider = styled('div')<{ vertical?: boolean; style?: CSSProperties }>`
  width: ${({ vertical }) => (vertical ? '0' : '100%')};
  height: ${({ vertical }) => (vertical ? '100%' : '0')};
  border-top: 1px solid ${theme.color.border};
  border-left: 1px solid ${theme.color.border};
  ${({ style }) => {
    return { ...style }
  }};
`

export default Divider
