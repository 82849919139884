import React, { ReactNode, useState } from 'react'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { addImpersonationParams, backendRequest } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'
import Loading from '../assets/Loading'
import { TextField } from '../components/InputFields'
import { CodeInline } from '../components/CodeBlock'
import { showToast, ToastType } from '../utils/toastify'

export const RampModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  switch (modal) {
    case IntgModal.RampCreate:
      return <RampCreateModal setModal={setModal} onDismiss={onDismiss} />
    case IntgModal.RampRemove:
      return (
        <RampRemoveModal
          onRemoveSuccess={() => setModal(IntgModal.RampRemovalSuccess)}
          onDismiss={onDismiss}
        />
      )
    case IntgModal.RampRemovalSuccess:
      return <RampRemoveSuccessModal onDismiss={onDismiss} />
    case IntgModal.RampSuccess:
      return <RampSuccessModal onDismiss={onDismiss} />
    case IntgModal.RampFailure:
      return <RampFailureModal onDismiss={onDismiss} />
    default:
      return <></>
  }
}

const RampModalWrapper = ({
  onDismiss,
  onClick,
  heading,
  body,
  buttonTxt,
  disableBtn,
  loading,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  heading: string
  body: ReactNode
  buttonTxt: string
  disableBtn?: boolean
  loading: boolean
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '500px', borderColor: theme.color.textPurple }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      {body}
      <Divider />
      <ButtonWrapper>
        <GhostButton
          onClick={onDismiss}
          style={{ padding: '14px 24px', borderColor: theme.color.textPurple }}
        >
          Cancel
        </GhostButton>
        {loading ? (
          <Button onClick={onClick} disabled={true}>
            <Loading />
          </Button>
        ) : (
          <Button onClick={onClick} disabled={disableBtn}>
            {buttonTxt}
          </Button>
        )}
      </ButtonWrapper>
    </div>
  </Modal>
)

const RampCreateModal = ({
  onDismiss,
  setModal,
}: {
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const [clientID, setClientID] = useState<string>('')
  const [clientSecret, setClientSecret] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = () => {
    setLoading(true)
    const createIntegration = async () => {
      const reqPath = addImpersonationParams(
        '/integrations/ramp',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath, {
        method: 'POST',
        body: JSON.stringify({
          client_id: clientID,
          client_secret: clientSecret,
        }),
      })
      if (resp.error) {
        setModal(IntgModal.RampFailure)
        return
      }
      setModal(IntgModal.RampSuccess)
    }
    createIntegration()
  }

  return (
    <RampModalWrapper
      onDismiss={onDismiss}
      onClick={onClick}
      heading="Integrate with Ramp"
      body={
        <>
          <HeadingSmallest color="textDefault">
            Connect your Ramp account to extract spend data. Diminish will pull
            and classify your Ramp transactions. Follow these steps to create a
            new App in Ramp:
          </HeadingSmallest>
          <HeadingSmallest>
            <ol
              style={{
                paddingLeft: '30px',
                margin: '0',
              }}
            >
              <li>
                Within your Ramp account, navigate to{' '}
                <b>Settings {'>'} Ramp developer</b>
              </li>
              <li>
                Click <b>Create new app</b>.
              </li>
              <li>
                Provide your app name, sign the Terms of Service, and click{' '}
                <b>Create</b>.
              </li>
              <li>
                Under Grant types, click <b>Add new grant type</b>
              </li>
              <li>
                Select <b>Client Credentials</b> and press <b>Add</b>
              </li>
              <li>
                Under Scopes, click <b>Configure allowed scopes</b>
              </li>
              <li>
                Select <CodeInline>transactions:read</CodeInline> and{' '}
                <CodeInline>receipts:read</CodeInline>, then press <b>Set</b>
              </li>
            </ol>
          </HeadingSmallest>
          <TextField
            label={'Ramp Client ID'}
            onChange={(newClientID) => setClientID(newClientID)}
          />
          <TextField
            label={'Ramp Client Secret'}
            onChange={(newClientSecret) => setClientSecret(newClientSecret)}
          />
        </>
      }
      buttonTxt="Create"
      disableBtn={clientID.length === 0 || clientSecret.length === 0}
      loading={loading}
    />
  )
}

const RampRemoveModal = ({
  onRemoveSuccess,
  onDismiss,
}: {
  onRemoveSuccess: () => void
  onDismiss: () => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = () => {
    setLoading(true)
    const createIntegration = async () => {
      const googleWorkspacePath = addImpersonationParams(
        '/integrations/ramp',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(googleWorkspacePath, {
        method: 'DELETE',
      })
      if (resp.error) {
        showToast('Failed to remove Ramp integration', ToastType.ERROR)

        return
      }
      onRemoveSuccess()
    }
    createIntegration()
  }

  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Are you sure you want to remove the Ramp integration?
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          If you remove this integration, Diminish will no longer pull financial
          transactions from your Ramp account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <GhostButton
            onClick={onDismiss}
            style={{
              padding: '14px 24px',
              borderColor: theme.color.errorRed,
            }}
          >
            Cancel
          </GhostButton>
          <Button onClick={onClick} disabled={loading} color="errorRed">
            {loading ? <Loading /> : 'Remove'}
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const RampRemoveSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <HeadingMedium color="textDefault">
          Ramp Integration Removed Successfully
        </HeadingMedium>
        <HeadingSmallest style={{ margin: '8px 0' }}>
          You have successfully removed your Ramp integration. Diminish can no
          longer pull financial transactions from your Ramp account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
const RampSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <HeadingMedium color="textDefault">
          Ramp Integration Successful
        </HeadingMedium>
        <HeadingSmallest style={{ margin: '8px 0' }}>
          You have successfully connected your Ramp account. Diminish will start
          pulling financial transactions from your Ramp account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const RampFailureModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Something Went Wrong
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          There was an issue creating the integration. Please contact support.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.errorRed,
              borderColor: theme.color.errorRed,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
