import styled from 'styled-components'
import theme from '../Theme'

const SVGWrapper = styled('span')<{ selected?: boolean }>`
  & > svg {
    color: ${theme.color.iconHover};
    position: relative;
    border-radius: 50%;
    ${({ selected }) =>
      selected ? { backgroundColor: theme.color.iconClick } : {}};
    &:hover {
      background-color: ${theme.color.backgroundHover};
    }
  }
`

const ThreeDots = ({
  selected,
  onClick,
  r,
}: {
  selected?: boolean
  onClick?: () => void
  r?: any
}) => (
  <SVGWrapper selected={selected} onClick={onClick}>
    <svg ref={r} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M12 19.275q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.063q.437-.437 1.062-.437t1.062.437q.438.438.438 1.063t-.438 1.062q-.437.438-1.062.438Zm0-5.775q-.625 0-1.062-.438Q10.5 12.625 10.5 12t.438-1.062Q11.375 10.5 12 10.5t1.062.438q.438.437.438 1.062t-.438 1.062q-.437.438-1.062.438Zm0-5.775q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.063q.437-.437 1.062-.437t1.062.437q.438.438.438 1.063t-.438 1.062q-.437.438-1.062.438Z" />
    </svg>
  </SVGWrapper>
)

export default ThreeDots
