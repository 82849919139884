import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import useAuth from '../hooks/useAuth'

interface ImpersonationContextType {
  impersonate: boolean
  setImpersonate: (impersonate: boolean) => void
  reqOrgId: number
  setReqOrgId: (reqOrgId: number) => void
  reqOrgName: string
  setReqOrgName: (reqOrgName: string) => void
  reqUserId: number
  setReqUserId: (reqUserId: number) => void
  reqUserEmail: string
  setReqUserEmail: (reqUserEmail: string) => void
  loadingInitial: boolean
}

export const ImpersonationContext = createContext<ImpersonationContextType>(
  {} as ImpersonationContextType
)

export function ImpersonationProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [impersonate, setImpersonate] = useState<boolean>(() => {
    const storedImpersonation = localStorage.getItem('impersonate')
    return storedImpersonation ? storedImpersonation === 'true' : false
  })
  const [reqOrgId, setReqOrgId] = useState<number>(() => {
    const storedReqOrgId = localStorage.getItem('reqOrgId')
    return storedReqOrgId ? parseInt(storedReqOrgId, 10) : -1
  })
  const [reqOrgName, setReqOrgName] = useState<string>(() => {
    const storedReqOrgName = localStorage.getItem('reqOrgName')
    return storedReqOrgName ? storedReqOrgName : ''
  })

  const [reqUserId, setReqUserId] = useState<number>(() => {
    const storedReqUserId = localStorage.getItem('reqUserId')
    return storedReqUserId ? parseInt(storedReqUserId, 10) : -1
  })

  const [reqUserEmail, setReqUserEmail] = useState<string>(() => {
    const storedReqUserEmail = localStorage.getItem('reqUserEmail')
    return storedReqUserEmail ? storedReqUserEmail : ''
  })

  const { canImpersonate } = useAuth()
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true)

  useEffect(() => {
    setLoadingInitial(false)
  }, [])

  useEffect(() => {
    if (canImpersonate) {
      localStorage.setItem('reqOrgId', reqOrgId.toString())
    }
  }, [reqOrgId, canImpersonate])

  useEffect(() => {
    if (canImpersonate) {
      localStorage.setItem('reqOrgName', reqOrgName.toString())
    }
  }, [reqOrgName, canImpersonate])

  useEffect(() => {
    if (canImpersonate) {
      localStorage.setItem('impersonate', impersonate.toString())
    }
  }, [impersonate, canImpersonate])

  useEffect(() => {
    if (canImpersonate) {
      localStorage.setItem('reqUserId', reqUserId.toString())
    }
  }, [reqUserId, canImpersonate])

  useEffect(() => {
    if (canImpersonate) {
      localStorage.setItem('reqUserEmail', reqUserEmail.toString())
    }
  }, [reqUserEmail, canImpersonate])

  const impersonationMemo = useMemo(
    () => ({
      impersonate,
      setImpersonate,
      reqOrgId,
      setReqOrgId,
      reqOrgName,
      setReqOrgName,
      reqUserId,
      setReqUserId,
      reqUserEmail,
      setReqUserEmail,
      loadingInitial,
    }),
    [
      impersonate,
      setImpersonate,
      reqOrgId,
      setReqOrgId,
      reqOrgName,
      setReqOrgName,
      reqUserId,
      setReqUserId,
      reqUserEmail,
      setReqUserEmail,
      loadingInitial,
    ]
  )

  return (
    <ImpersonationContext.Provider value={impersonationMemo}>
      {!loadingInitial && children}
    </ImpersonationContext.Provider>
  )
}

export default function useImpersonation() {
  return useContext(ImpersonationContext)
}
