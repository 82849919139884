import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const SortArrows = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={{ transform: 'rotate(270deg)', ...style }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path d="M5 12 L10 7 L10 10 L10 15 L10 17 Z" fill="currentColor" />
      <path d="M20 12 L15 7 L15 10 L15 15 L15 17 Z" fill="currentColor" />
    </svg>
  </SVGWrapper>
)

export default SortArrows
