import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const GroupPeople = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        fill="white"
        d="M.5 17.8v-1.225q0-1.025 1.05-1.65t2.7-.625q.325 0 .6.012.275.013.525.063-.275.45-.425.95-.15.5-.15 1.05V17.8Zm6 0v-1.375q0-1.45 1.525-2.312Q9.55 13.25 12 13.25q2.475 0 3.988.863 1.512.862 1.512 2.312V17.8Zm12.7 0v-1.425q0-.55-.138-1.05-.137-.5-.412-.95.275-.05.55-.063.275-.012.55-.012 1.675 0 2.712.625 1.038.625 1.038 1.65V17.8ZM12 14.75q-1.55 0-2.675.412-1.125.413-1.25 1.013v.125h7.875v-.125q-.15-.6-1.262-1.013-1.113-.412-2.688-.412Zm-7.75-1.425q-.7 0-1.2-.513-.5-.512-.5-1.212t.5-1.2q.5-.5 1.2-.5.725 0 1.225.5.5.5.5 1.2t-.5 1.212q-.5.513-1.225.513Zm15.5 0q-.7 0-1.2-.513-.5-.512-.5-1.212t.5-1.2q.5-.5 1.2-.5.725 0 1.213.5.487.5.487 1.2t-.487 1.212q-.488.513-1.213.513ZM12 12.5q-1.075 0-1.837-.762Q9.4 10.975 9.4 9.9q0-1.1.763-1.85.762-.75 1.837-.75 1.1 0 1.85.75t.75 1.85q0 1.075-.75 1.838-.75.762-1.85.762Zm0-3.7q-.45 0-.775.325T10.9 9.9q0 .45.325.775T12 11q.475 0 .788-.325.312-.325.312-.775t-.312-.775Q12.475 8.8 12 8.8Zm0 7.5Zm0-6.4Z"
      />
    </svg>
  </SVGWrapper>
)
export default GroupPeople
