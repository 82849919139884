import { CSSProperties } from 'react'
import theme from '../Theme'
import SVGWrapper from './SVGWrapper'

const ArrowDropDown = ({
  style,
  onClick,
}: {
  style?: CSSProperties
  onClick?: () => void
}) => (
  <SVGWrapper style={style} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path fill="currentColor" d="m12 15-5-5h10Z" />
    </svg>
  </SVGWrapper>
)

export const ArrowUp = () => (
  <ArrowDropDown
    style={{
      color: theme.color.successGreen,
      transform: 'rotate(180deg)',
      height: '24px',
    }}
  />
)

export const ArrowDown = () => (
  <ArrowDropDown style={{ color: theme.color.errorRed, height: '24px' }} />
)

export default ArrowDropDown
