import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button, { GhostButton } from '../components/Button'
import Dropdown from '../components/Dropdown'
import { TextField } from '../components/InputFields'
import Modal from '../components/Modal'
import { UserTable } from './UserTable'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmallest,
} from '../components/Text'
import useAuth from '../hooks/useAuth'
import theme from '../Theme'
import Loading from '../assets/Loading'
import { Roles, getRoles } from '../api/user'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import DownloadIcon from '../assets/Download'

// trigger webui change for debug

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '16px',
  width: '100%',
  marginBottom: '24px',
})
const HeadingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '16px',
  width: '100%',
  paddingBottom: '24px',
})

export type UserType = {
  hash: string
  first_name: string
  last_name: string
  email: string
  title: string
  department: string
  profile_picture: string
  access_level: string
  app_urls: string[]
  num_visits: number
  last_visit: string
}

export enum AccessLevel {
  Member = 'Member',
  Manager = 'Manager',
  Admin = 'Admin',
}

enum UserModal {
  DISABLED = 0,
  CANNOT_CHANGE_PERMISSION,
  CAN_CHANGE_PERMISSION,
}

const Users = () => {
  const [users, setUsers] = useState<UserType[] | null | undefined>(undefined)
  const [showUserModal, setShowUserModal] = useState<UserModal>(
    UserModal.DISABLED
  )
  const [roles, setRoles] = useState<Roles | undefined>(undefined)
  const { permissions } = useAuth()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        `/users`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(
          `Failed to fetch users. Please try again later.`,
          ToastType.ERROR
        )
        setUsers(null)
        return
      }
      const sortedUsers = (resp as UserType[]).sort((a, b) =>
        a.email > b.email ? 1 : -1
      )
      setUsers(sortedUsers || [])
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const reqRoles = async () => {
      const roles: Roles = await getRoles()
      setRoles(roles)
    }

    reqRoles()
  }, [])

  if (users === undefined) {
    return <Loading large />
  }
  if (users === null) {
    return (
      <HeadingLarge>Something went wrong, please try again later.</HeadingLarge>
    )
  }

  const handleRoleChange = (modifiedUser: UserType) => {
    const newUsers = users.map((user) => {
      if (modifiedUser.email === user.email) {
        return modifiedUser
      }
      return user
    })
    setUsers(newUsers)
  }

  const handleUserArchive = (modifiedUser: UserType) => {
    const newUsers = users.filter((user) => {
      return modifiedUser.email !== user.email
    })
    setUsers(newUsers)
  }

  const onAddUser = (user: UserType) => {
    setUsers([...users, user])
  }

  const downloadUsers = () => {
    if (!users) return
    const csv = users.map((user) => {
      return `${user.email},${user.first_name},${user.last_name},${user.last_visit}`
    })
    csv.unshift(
      ['Email Address', 'First Name', 'Last Name', 'Last Activity'].join(',')
    )
    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-users-${new Date().toISOString()}.csv`
    a.click()
  }

  const adminUsers = users.filter((u) => u.access_level !== 'Member')
  const memberUsers = users.filter((u) => u.access_level === 'Member')

  return (
    <>
      {showUserModal !== 0 && (
        <AddModal
          onDismiss={() => setShowUserModal(UserModal.DISABLED)}
          onSubmitCallback={onAddUser}
        />
      )}
      <HeadingWrapper
        style={{
          position: 'sticky',
          top: '77px',
          background: theme.color.backgroudDefault,
          zIndex: 5,
        }}
      >
        <div style={{ maxWidth: '800px' }}>
          <HeadingLarge style={{ marginBottom: '8px' }}>
            Admins & Managers
            <span style={{ fontWeight: '400', fontSize: '14px' }}>
              {adminUsers.length > 0 && ` (${adminUsers.length})`}
            </span>
          </HeadingLarge>
          <HeadingSmallest>
            This is the list of admins and managers in your organization. Only
            admins can manage integrations.
          </HeadingSmallest>
        </div>

        {permissions && permissions.add_managers_admins && (
          <Button
            onClick={() => setShowUserModal(UserModal.CAN_CHANGE_PERMISSION)}
          >
            Add New Manager/Admin
          </Button>
        )}
      </HeadingWrapper>
      <UserTable
        roles={roles}
        access_level={AccessLevel.Admin}
        userList={adminUsers}
        handleRoleChange={handleRoleChange}
        handleUserArchive={handleUserArchive}
      />
      <HeadingWrapper
        style={{
          marginTop: '40px',
          position: 'sticky',
          top: '77px',
          background: theme.color.backgroudDefault,
          zIndex: 5,
        }}
      >
        <div style={{ maxWidth: '800px' }}>
          <HeadingLarge style={{ marginBottom: '8px' }}>
            Members
            <span style={{ fontWeight: '400', fontSize: '14px' }}>
              {memberUsers.length > 0 && ` (${memberUsers.length})`}
            </span>
          </HeadingLarge>
          <HeadingSmallest>
            This is the list of members in your organization. If you have Google
            Workspace or Microsoft 365 integration enabled, this list is auto
            populated by our system. Members do not have access to this
            dashboard.
          </HeadingSmallest>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {permissions && permissions.add_members && (
            <Button
              onClick={() =>
                setShowUserModal(UserModal.CANNOT_CHANGE_PERMISSION)
              }
            >
              Add New Member
            </Button>
          )}
          <GhostButton onClick={downloadUsers} style={{ padding: '12px' }}>
            <DownloadIcon />
          </GhostButton>
        </div>
      </HeadingWrapper>
      <UserTable
        roles={roles}
        access_level={AccessLevel.Member}
        userList={memberUsers}
        handleRoleChange={handleRoleChange}
        handleUserArchive={handleUserArchive}
      />
    </>
  )
}

const AddModal = ({
  onDismiss,
  onSubmitCallback,
}: {
  onDismiss: () => void
  onSubmitCallback: (user: UserType) => void
}) => {
  const { permissions } = useAuth()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const [accessLevel, setAccessLevel] = useState<AccessLevel>(
    AccessLevel.Member
  )
  const [userEmail, setUserEmail] = useState('')
  const setUserAccess = (input: string) => {
    switch (input) {
      case 'Admin':
        setAccessLevel(AccessLevel.Admin)
        break
      case 'Manager':
        setAccessLevel(AccessLevel.Manager)
        break
      case 'Member':
      default:
        setAccessLevel(AccessLevel.Member)
    }
  }

  const onSubmit = async () => {
    const reqPath = addImpersonationParams(
      `/users/new`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(reqPath, {
      method: 'POST',
      body: JSON.stringify({
        email: userEmail,
        access_level: accessLevel,
      }),
    })

    onDismiss()
    if (resp.error) {
      showToast(`Failed to create user`, ToastType.ERROR)
      return
    }
    onSubmitCallback({
      email: userEmail,
      access_level: accessLevel,
    } as UserType)
    showToast(`Successfully created user`, ToastType.SUCCESS)
  }

  return (
    <Modal
      onDismiss={onDismiss}
      style={{
        width: '450px',
        borderColor: theme.color.backgroundPurple,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <HeadingMedium style={{ marginBottom: '12px' }} color="textDefault">
          Add New Member
        </HeadingMedium>
        <TextField
          onChange={(e) => setUserEmail(e)}
          label="Email"
          type="text"
          name="email"
          placeholder="Enter one email at a time"
          marginBottom="34px"
        />
        {permissions && permissions.edit_managers_admins ? (
          <Dropdown
            label={'Access level'}
            options={['Member', 'Manager', 'Admin']}
            defaultValue="Member"
            onSelect={(selection) => setUserAccess(selection)}
            style={{ marginBottom: '40px' }}
          />
        ) : (
          <Dropdown
            disabled={true}
            label={'Access level'}
            options={[]}
            defaultValue="Member"
            onSelect={(s) => {}}
            style={{ marginBottom: '40px' }}
          />
        )}
        <ButtonWrapper>
          <Button onClick={onSubmit}>Send Invite</Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

export default Users
