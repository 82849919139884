import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const CloseX = ({
  style,
  onClick,
  className,
}: {
  style?: CSSProperties
  onClick?: () => void
  className?: string
}) => (
  <SVGWrapper style={style} onClick={onClick} className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
    </svg>
  </SVGWrapper>
)
export default CloseX
