import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const Paper = ({
  style,
  onClick,
  className,
}: {
  style?: CSSProperties
  onClick?: () => void
  className?: string
}) => (
  <SVGWrapper style={style} onClick={onClick} className={className}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00128 17.9163C4.41903 17.9163 3.92625 17.7146 3.52293 17.3113C3.11962 16.908 2.91797 16.4152 2.91797 15.833V13.4292H5.41797V2.08301H17.0846V15.833C17.0846 16.4152 16.8829 16.908 16.4796 17.3113C16.0763 17.7146 15.5835 17.9163 15.0013 17.9163H5.00128ZM15.0013 16.6663C15.2374 16.6663 15.4353 16.5865 15.595 16.4267C15.7548 16.267 15.8346 16.0691 15.8346 15.833V3.33299H6.66795V13.4292H14.1679V15.833C14.1679 16.0691 14.2478 16.267 14.4075 16.4267C14.5673 16.5865 14.7652 16.6663 15.0013 16.6663ZM7.66155 7.24322V5.99326H14.841V7.24322H7.66155ZM7.66155 9.64707V8.39709H14.841V9.64707H7.66155ZM5.00128 16.6663H12.918V14.6791H4.16795V15.833C4.16795 16.0691 4.24781 16.267 4.40753 16.4267C4.56725 16.5865 4.76517 16.6663 5.00128 16.6663ZM5.00128 16.6663H4.16795H12.918H5.00128Z"
        fill="#3A393B"
      />
    </svg>
  </SVGWrapper>
)
export default Paper
