import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const BarChartEmpty = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg
      width="427"
      height="197"
      viewBox="0 0 427 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1681_43455)">
        <path
          d="M227.24 166V98.9062C227.24 97.8017 228.136 96.9062 229.24 96.9062H247.281C248.386 96.9062 249.281 97.8017 249.281 98.9062V166H227.24ZM266.6 166V34C266.6 32.8954 267.495 32 268.6 32H286.641C287.745 32 288.641 32.8954 288.641 34V166H266.6ZM305.959 166L305.959 91.4286C305.959 90.324 306.854 89.4286 307.959 89.4286H326C327.105 89.4286 328 90.324 328 91.4286L328 166H305.959Z"
          fill="url(#paint0_linear_1681_43455)"
        />
      </g>
      <g filter="url(#filter1_d_1681_43455)">
        <path
          d="M99 166V97.0588C99 95.9543 99.8954 95.0588 101 95.0588H121.069C122.173 95.0588 123.069 95.9543 123.069 97.0588V166H99ZM141.98 166V65.5294C141.98 64.4248 142.876 63.5294 143.98 63.5294H164.049C165.154 63.5294 166.049 64.4248 166.049 65.5294V166H141.98ZM184.96 166V139.098C184.96 137.993 185.856 137.098 186.96 137.098H207.029C208.134 137.098 209.029 137.993 209.029 139.098V166H184.96Z"
          fill="#3A393B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1681_43455"
          x="223.24"
          y="29"
          width="104.76"
          height="137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1681_43455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1681_43455"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1681_43455"
          x="95"
          y="60.5294"
          width="114.029"
          height="105.471"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1681_43455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1681_43455"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1681_43455"
          x1="286.301"
          y1="121.732"
          x2="328.3"
          y2="121.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A393B" />
          <stop offset="1" stopColor="#6011B1" />
        </linearGradient>
      </defs>
    </svg>
  </SVGWrapper>
)
export default BarChartEmpty
