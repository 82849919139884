import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const NineDots = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        fill="white"
        d="M6 19.625q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm-12-6q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm-12-6q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Zm6 0q-.675 0-1.15-.475-.475-.475-.475-1.15 0-.675.475-1.15.475-.475 1.15-.475.675 0 1.15.475.475.475.475 1.15 0 .675-.475 1.15-.475.475-1.15.475Z"
      />
    </svg>
  </SVGWrapper>
)
export default NineDots
