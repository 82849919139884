import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const ContractsArchiveEmpty = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2363_58501)">
        <path
          d="M15.1663 86.775V36.2917C14.1552 36.2194 13.1802 35.5514 12.2413 34.2875C11.3025 33.0236 10.833 31.6333 10.833 30.1167V19.2833C10.833 17.6222 11.4469 16.1597 12.6747 14.8958C13.9025 13.6319 15.383 13 17.1163 13H86.883C88.5441 13 90.0066 13.6319 91.2705 14.8958C92.5344 16.1597 93.1663 17.6222 93.1663 19.2833V30.1167C93.1663 31.6333 92.6969 33.0236 91.758 34.2875C90.8191 35.5514 89.8441 36.2194 88.833 36.2917V86.775C88.833 88.4361 88.2011 89.9167 86.9372 91.2167C85.6733 92.5167 84.2108 93.1667 82.5497 93.1667H21.4497C19.7163 93.1667 18.2358 92.5167 17.008 91.2167C15.7802 89.9167 15.1663 88.4361 15.1663 86.775ZM86.883 31.4167C87.3163 31.4167 87.6594 31.3083 87.9122 31.0917C88.165 30.875 88.2913 30.55 88.2913 30.1167V19.2833C88.2913 18.85 88.165 18.5069 87.9122 18.2542C87.6594 18.0014 87.3163 17.875 86.883 17.875H17.1163C16.683 17.875 16.34 18.0014 16.0872 18.2542C15.8344 18.5069 15.708 18.85 15.708 19.2833V30.1167C15.708 30.55 15.8344 30.875 16.0872 31.0917C16.34 31.3083 16.683 31.4167 17.1163 31.4167H86.883ZM39.8663 57.525H64.133V52.5417H39.8663V57.525Z"
          fill="#3A393B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2363_58501"
          x="6.83301"
          y="10"
          width="86.3335"
          height="83.1665"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="-3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2363_58501"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2363_58501"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </SVGWrapper>
)
export default ContractsArchiveEmpty
