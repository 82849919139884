import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const TooManyFiltersTableIcon = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_6664_63325)">
        <path
          d="M84.6824 89.1672L57.4658 61.9496C55.2992 63.7385 52.8075 65.1385 49.9908 66.1496C47.1742 67.1608 44.2604 67.6663 41.2494 67.6663C33.843 67.6663 27.5749 65.1021 22.4449 59.9736C17.315 54.8451 14.75 48.5783 14.75 41.1734C14.75 33.7692 17.3142 27.5003 22.4427 22.3667C27.5712 17.2339 33.838 14.6675 41.2429 14.6675C48.6471 14.6675 54.916 17.2324 60.0496 22.3624C65.1824 27.4923 67.7488 33.7605 67.7488 41.1669C67.7488 44.2616 67.2296 47.2173 66.191 50.034C65.1517 52.8507 63.7654 55.3004 62.0321 57.3833L89.2487 84.5999L84.6824 89.1672ZM41.2494 61.1674C46.8329 61.1674 51.562 59.2297 55.4368 55.3542C59.3122 51.4795 61.2499 46.7504 61.2499 41.1669C61.2499 35.5834 59.3122 30.8543 55.4368 26.9796C51.562 23.1041 46.8329 21.1664 41.2494 21.1664C35.6659 21.1664 30.9368 23.1041 27.0621 26.9796C23.1866 30.8543 21.2489 35.5834 21.2489 41.1669C21.2489 46.7504 23.1866 51.4795 27.0621 55.3542C30.9368 59.2297 35.6659 61.1674 41.2494 61.1674Z"
          fill="#3A393B"
        />
      </g>
      <circle cx="27.668" cy="72.333" r="13" fill="white" />
      <path
        d="M20.8941 81.0001L27.6649 74.2292L34.4358 81.0001L36.3316 79.1042L29.5608 72.3334L36.3316 65.5626L34.4358 63.6667L27.6649 70.4376L20.8941 63.6667L18.9983 65.5626L25.7691 72.3334L18.9983 79.1042L20.8941 81.0001ZM27.6649 90.3889C25.1973 90.3889 22.8652 89.915 20.6684 88.9671C18.4716 88.0191 16.5532 86.7252 14.9132 85.0851C13.2731 83.4451 11.9792 81.5267 11.0312 79.3299C10.0833 77.1332 9.60938 74.801 9.60938 72.3334C9.60938 69.8357 10.0833 67.4885 11.0312 65.2917C11.9792 63.095 13.2731 61.1841 14.9132 59.5591C16.5532 57.9341 18.4716 56.6476 20.6684 55.6997C22.8652 54.7518 25.1973 54.2778 27.6649 54.2778C30.1626 54.2778 32.5098 54.7518 34.7066 55.6997C36.9034 56.6476 38.8142 57.9341 40.4392 59.5591C42.0642 61.1841 43.3507 63.095 44.2986 65.2917C45.2465 67.4885 45.7205 69.8357 45.7205 72.3334C45.7205 74.801 45.2465 77.1332 44.2986 79.3299C43.3507 81.5267 42.0642 83.4451 40.4392 85.0851C38.8142 86.7252 36.9034 88.0191 34.7066 88.9671C32.5098 89.915 30.1626 90.3889 27.6649 90.3889Z"
        fill="#6011B1"
      />
      <defs>
        <filter
          id="filter0_d_6664_63325"
          x="11.75"
          y="12.6675"
          width="77.5"
          height="76.4995"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-3" dy="-2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6664_63325"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6664_63325"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </SVGWrapper>
)
export default TooManyFiltersTableIcon
