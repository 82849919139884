import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ArrowDown, ArrowUp } from '../assets/ArrowDropDown'
import BarChart from '../assets/BarChart'
import Loading from '../assets/Loading'
import NineDots from '../assets/NineDots'
import RadioButtonChecked from '../assets/RadioButtonChecked'
import UserTableEmpty from '../assets/UserTableEmpty'
import BackToPage from '../components/BackToPage'
import Dropdown from '../components/Dropdown'
import {
  ApplicationCell,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
} from '../components/Table'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import useAuth from '../hooks/useAuth'
import { ContractCard } from '../Overview/Product'
import { ProfileHeaderLarge } from '../pages/Profile'
import theme from '../Theme'
import {
  dateToShortFormat,
  getDateDiff,
  getThisMonth,
  backendRequest,
  addImpersonationParams,
  getPastNMonths,
  getWeeks,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { GetDefaultDateOptions } from '../utils/dates'
import { getUserName } from '../utils/users'
import { Pagination } from '../Overview/ApplicationsTable'

const CardsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '16px',
  width: '100%',
  marginBottom: '24px',
})

export type UserInfo = {
  first_name: string
  last_name: string
  email: string
  title: string
  department: string
  profile_picture: string
  num_apps: number
  num_visits: number
  prev_num_apps: number
  prev_num_visits: number
  last_visit: string
  apps: App[]
}

type App = {
  id: number
  name: string
  logo_url: string
  category: string
  num_visits: number
}

const User = () => {
  const dropdownOptions = GetDefaultDateOptions()

  const [user, setUser] = useState<UserInfo | null | undefined>(undefined)
  const [dateFilter, setDateFilter] = useState<string>(dropdownOptions[0][0])

  const { showHris, productivityTable } = useFlags()

  const { permissions } = useAuth()
  const { user_hash } = useParams()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    const getUser = async () => {
      const filter = dateFilter.toLowerCase().replaceAll(' ', '_')
      const reqPath = addImpersonationParams(
        `/users/${user_hash}?filter=${filter}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch user data`, ToastType.ERROR)
        setUser(null)
        return
      }
      setUser(resp)
    }
    getUser()
  }, [user_hash, dateFilter, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (user) {
      document.title = `Diminish - User - ${getUserName(user)}`
    }
  }, [user])

  if (user === undefined) {
    return <Loading large />
  }
  if (user === null || !permissions || !permissions.see_users) {
    return <HeadingLarge>User not found. Please contact support</HeadingLarge>
  }

  return (
    <>
      <BackToPage path={'/users'} pageName={'Users'} />
      <ProfileHeaderLarge profile={user} />
      {showHris ? <OverviewCard user={user} /> : <StatusCards user={user} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: '16px',
        }}
      >
        <HeadingMedium color="textDefault">
          Apps Used
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {user.apps.length > 0 && ` (${user.apps.length})`}
          </span>
        </HeadingMedium>
        <Dropdown
          defaultValue={dateFilter}
          options={dropdownOptions}
          onSelect={(selected: string) => setDateFilter(selected)}
        />
      </div>
      <UsageTable apps={user.apps} />
      {productivityTable && <WeeklyUsageTable />}
    </>
  )
}
const Card = styled('div')({
  minHeight: '100px',
  display: 'flex',
  gap: '11px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '8px',
  backgroundColor: theme.color.white,
  padding: '16px 24px',
  marginBottom: '33px',
  flexGrow: 1,
})

const AppsUsed = ({ user, curMonth }: { user: UserInfo; curMonth: string }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {user.num_apps === 0 ? (
      '-'
    ) : (
      <>
        {user.num_apps.toString()}
        {user.num_apps > user.prev_num_apps ? (
          <>
            <ArrowUp />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              (+
              {user.num_apps - user.prev_num_apps} in {curMonth})
            </HeadingSmallest>
          </>
        ) : (
          <>
            <ArrowDown />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              ({user.num_apps - user.prev_num_apps} in {curMonth})
            </HeadingSmallest>
          </>
        )}
      </>
    )}
  </div>
)

const TotalVisits = ({
  user,
  curMonth,
}: {
  user: UserInfo
  curMonth: string
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {user.num_visits === 0 ? (
      '-'
    ) : (
      <>
        {user.num_visits.toString()}
        {user.num_visits > user.prev_num_visits ? (
          <>
            <ArrowUp />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              (+
              {user.num_visits - user.prev_num_visits} in {curMonth})
            </HeadingSmallest>
          </>
        ) : (
          <>
            <ArrowDown />
            <HeadingSmallest style={{ color: theme.color.textSubdued }}>
              ({user.num_visits - user.prev_num_visits} in {curMonth})
            </HeadingSmallest>
          </>
        )}
      </>
    )}
  </div>
)

const UserStatus = ({
  user,
  isActive,
}: {
  user: UserInfo
  isActive: boolean
}) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    {isActive ? (
      <>
        <RadioButtonChecked
          style={{ color: theme.color.successGreen, height: '24px' }}
          large
        />
        <span>Active</span>
      </>
    ) : (
      <>
        <RadioButtonChecked
          style={{ color: theme.color.errorRed, height: '24px' }}
          large
        />
        <span>Inactive</span>
        {user.last_visit.length === 0 ? (
          ''
        ) : (
          <HeadingSmallest color="textSubdued">
            (since {dateToShortFormat(user.last_visit)})
          </HeadingSmallest>
        )}
      </>
    )}
  </div>
)

const Title = styled(HeadingSmallest)({
  color: theme.color.textSubdued,
  borderBottom: `1px solid ${theme.color.border}`,
  height: '40px',
  marginBottom: '16px',
})

const FlexGrow = styled('div')({
  flexGrow: 1,
})

const OverviewCard = ({ user }: { user: UserInfo }) => {
  const isActiveUser = () => {
    return (
      user.last_visit.length > 0 &&
      getDateDiff(new Date(), new Date(user.last_visit)) < 14
    )
  }

  const curMonth = getThisMonth().split(' ')[0] // e.g. `Mar`
  return (
    <CardsWrapper>
      <Card>
        <div style={{ display: 'flex', width: '100%' }}>
          <HeadingSmall color="textDefault" style={{ flexGrow: 1 }}>
            Overview
          </HeadingSmall>
          <FlexGrow>
            <Title>Department</Title>
            <HeadingSmallest>{user.department || '-'}</HeadingSmallest>
          </FlexGrow>
          <FlexGrow>
            <Title>Title</Title>
            <HeadingSmallest>{user.title || '-'}</HeadingSmallest>
          </FlexGrow>
          <FlexGrow>
            <Title>Applications</Title>
            <AppsUsed user={user} curMonth={curMonth} />
          </FlexGrow>
          <FlexGrow>
            <Title>Total Visits</Title>
            <TotalVisits user={user} curMonth={curMonth} />
          </FlexGrow>
          <FlexGrow>
            <Title>User Status</Title>
            <UserStatus user={user} isActive={isActiveUser()} />
          </FlexGrow>
        </div>
      </Card>
    </CardsWrapper>
  )
}

const StatusCards = ({ user }: { user: UserInfo }) => {
  const isActiveUser = () => {
    return (
      user.last_visit.length > 0 &&
      getDateDiff(new Date(), new Date(user.last_visit)) < 14
    )
  }

  const curMonth = getThisMonth().split(' ')[0] // e.g. `Mar`

  return (
    <CardsWrapper>
      <ContractCard
        Icon={NineDots}
        title={'Applications'}
        value={<AppsUsed user={user} curMonth={curMonth} />}
      />
      <ContractCard
        Icon={BarChart}
        title={<>Total Visits </>}
        value={<TotalVisits user={user} curMonth={curMonth} />}
      />
      <ContractCard
        Icon={RadioButtonChecked}
        title={'User Status'}
        value={<UserStatus user={user} isActive={isActiveUser()} />}
      />
    </CardsWrapper>
  )
}

const EmptyUsageTable = ({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <div
        style={{
          borderTop: `1px solid ${theme.color.border}`,
          borderBottom: `1px solid ${theme.color.border}`,
          margin: '15px 0',
          padding: '24px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UserTableEmpty />
        <HeadingSmall>No data found for the selected period</HeadingSmall>
      </div>
    </TableCell>
  </TableRow>
)

const UsageTable = ({ apps }: { apps: App[] }) => {
  const [page, setPage] = useState(0)
  const pageSize = 10

  const pageCount = useMemo(() => {
    if (!apps) {
      return 0
    }
    return Math.ceil(apps.length / pageSize)
  }, [apps])

  const paginatedApps = useMemo(() => {
    if (!apps) {
      return []
    }
    return apps
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(page * pageSize, (page + 1) * pageSize)
  }, [apps, page])

  return (
    <>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell mw="180px">Application</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Visits</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.length === 0 ? (
            <EmptyUsageTable colSpan={3} />
          ) : (
            paginatedApps
              .sort((a, b) => b.num_visits - a.num_visits)
              .map((app, i) => (
                <TableRow key={i}>
                  <ApplicationCell
                    id={app.id}
                    logo={app.logo_url}
                    name={app.name}
                  />
                  <TableCell mw="100px">{app.category}</TableCell>
                  <TableCell>
                    {app.num_visits > 0 ? app.num_visits : '-'}
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </TableWrapper>
      <Pagination
        page={page}
        pageCount={pageCount}
        total={apps?.length || 0}
        setPage={setPage}
      />
    </>
  )
}

type WeeklyApp = {
  id: number
  name: string
  logo_url: string
  sunday: number
  monday: number
  tuesday: number
  wednesday: number
  thursday: number
  friday: number
  saturday: number
}

const WeeklyUsageTable = () => {
  const monthOptions = getPastNMonths(6)

  const [dateFilter, setDateFilter] = useState<string>(monthOptions[0])
  const [weekOptions, setWeekOptions] = useState<string[]>(getWeeks(dateFilter))
  const [weekFilter, setWeekFilter] = useState<string>(weekOptions[0])
  const [apps, setApps] = useState<WeeklyApp[] | undefined>(undefined)

  useEffect(() => {
    setWeekOptions(getWeeks(dateFilter))
    setWeekFilter(getWeeks(dateFilter)[0])
  }, [dateFilter])

  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  const { user_hash } = useParams()
  useEffect(() => {
    const filter = weekFilter.toLowerCase().replaceAll(' ', '_')
    const fetchUser = async () => {
      const reqPath = addImpersonationParams(
        `/users/${user_hash}/week?filter=${filter}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch user data`, ToastType.ERROR)
        setApps([])
        return
      }
      setApps(resp)
    }
    fetchUser()
  }, [user_hash, weekFilter, reqOrgId, reqUserId, impersonate])

  if (apps === undefined) {
    return <Loading large />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '30px 0 10px',
        }}
      >
        <HeadingMedium color="textDefault">
          Productivity
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {apps.length > 0 && ` (${apps.length})`}
          </span>
        </HeadingMedium>
        <div style={{ display: 'flex', gap: '12px' }}>
          <Dropdown
            defaultValue={weekFilter}
            options={weekOptions}
            onSelect={(selected: string) => setWeekFilter(selected)}
          />
          <Dropdown
            defaultValue={dateFilter}
            options={monthOptions}
            onSelect={(selected: string) => setDateFilter(selected)}
          />
        </div>
      </div>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell mw="180px">Application</TableCell>
            <TableCell>Sunday</TableCell>
            <TableCell>Monday</TableCell>
            <TableCell>Tuesday</TableCell>
            <TableCell>Wednesday</TableCell>
            <TableCell>Thursday</TableCell>
            <TableCell>Friday</TableCell>
            <TableCell>Saturday</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.length === 0 ? (
            <EmptyUsageTable colSpan={10} />
          ) : (
            apps.map((app, i) => (
              <TableRow key={i}>
                <ApplicationCell
                  id={app.id}
                  logo={app.logo_url}
                  name={app.name}
                />
                <TableCell>{app.sunday || '-'}</TableCell>
                <TableCell>{app.monday || '-'}</TableCell>
                <TableCell>{app.tuesday || '-'}</TableCell>
                <TableCell>{app.wednesday || '-'}</TableCell>
                <TableCell>{app.thursday || '-'}</TableCell>
                <TableCell>{app.friday || '-'}</TableCell>
                <TableCell>{app.saturday || '-'}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
    </>
  )
}
export default User
