export const getUserName = (user: {
  first_name: string
  last_name: string
  email: string
}) => {
  if (user.first_name || user.last_name) {
    return `${user.first_name} ${user.last_name}`
  }
  return user.email
}
