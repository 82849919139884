import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const RadioButtonChecked = ({
  style,
  large,
}: {
  style?: CSSProperties
  large?: boolean
}) => (
  <SVGWrapper style={style}>
    {large ? (
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
        <path
          fill="currentColor"
          d="M12 17q2.075 0 3.538-1.463Q17 14.075 17 12t-1.462-3.538Q14.075 7 12 7 9.925 7 8.463 8.462 7 9.925 7 12q0 2.075 1.463 3.537Q9.925 17 12 17Zm0 5q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
        <path
          fill="#fff"
          d="M12 16q1.675 0 2.838-1.163Q16 13.675 16 12q0-1.675-1.162-2.838Q13.675 8 12 8T9.163 9.162Q8 10.325 8 12t1.163 2.837Q10.325 16 12 16Zm0 5q-1.875 0-3.512-.712-1.638-.713-2.85-1.926-1.213-1.212-1.926-2.85Q3 13.875 3 12t.712-3.513q.713-1.637 1.926-2.85 1.212-1.212 2.85-1.925Q10.125 3 12 3t3.513.712q1.637.713 2.85 1.925 1.212 1.213 1.925 2.85Q21 10.125 21 12t-.712 3.512q-.713 1.638-1.925 2.85-1.213 1.213-2.85 1.926Q13.875 21 12 21Zm0-1q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
        />
      </svg>
    )}
  </SVGWrapper>
)
export default RadioButtonChecked
