import { getPastNMonths, getPastNQuarters } from './utils'

export const GetDefaultDateOptions = () => {
  const dropdownOptions = []
  dropdownOptions.push([
    'Last 30 Days',
    'Last 60 Days',
    'Last 90 Days',
    'Last 12 Months',
  ])
  dropdownOptions.push(getPastNQuarters(4))
  dropdownOptions.push([
    `This year (${new Date().getFullYear()})`,
    `Last year (${new Date().getFullYear() - 1})`,
  ])
  dropdownOptions.push(getPastNMonths(13))
  return dropdownOptions
}
