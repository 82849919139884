import { useEffect, useState } from 'react'
import { HeadingSmallest } from '../../components/Text'
import Divider from '../../components/Divider'
import {
  LoginCard,
  PageWrapper,
  PurpleLink,
  WelcomeText,
} from '../../components/LoginComponents'
import Button from '../../components/Button'
import { TextField } from '../../components/InputFields'
import OktaAuth from '@okta/okta-auth-js'
import useAuth from '../../hooks/useAuth'

export default function LoginOkta() {
  const [domain, setDomain] = useState<string>('')
  const [oktaSSO, setOktaSSO] = useState<{ url: string; client_id: string }>({
    url: '',
    client_id: '',
  })
  const [error, setError] = useState<string | null>(null)

  const { getOktaSSO } = useAuth()

  const getDomain = async () => {
    const [oktaSSO, err] = await getOktaSSO(domain)
    if (err) {
      setError(err)
      return
    }
    setOktaSSO(oktaSSO)
  }

  useEffect(() => {
    if (oktaSSO.url) {
      const oktaAuth = new OktaAuth({
        issuer: oktaSSO.url,
        clientId: oktaSSO.client_id,
        redirectUri: window.location.origin + '/login/callback',
      })
      oktaAuth.signInWithRedirect({ pkce: false, responseType: 'code' })
    }
  }, [oktaSSO])

  return (
    <PageWrapper>
      <LoginCard>
        <WelcomeText>Welcome to Diminish</WelcomeText>
        <Divider style={{ margin: '16px 0' }} />
        <HeadingSmallest color="textSubdued" style={{ marginBottom: '24px' }}>
          Enter your domain to log in
        </HeadingSmallest>
        <TextField
          label="Domain"
          type="text"
          name="domain"
          placeholder="Enter your domain"
          onChange={(value) => setDomain(value)}
          marginBottom="32px"
          errorMsg={error || ''}
        />
        <PurpleLink href="/login">Log in without SSO</PurpleLink>
        <Button disabled={domain.length === 0} onClick={getDomain}>
          Next
        </Button>
      </LoginCard>
    </PageWrapper>
  )
}
