import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const OverviewTableIcon = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg
      width="374"
      height="104"
      viewBox="0 0 374 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2369_56500)">
        <path
          d="M110 82C110 83.1046 109.105 84 108 84H48C46.8954 84 46 83.1046 46 82V65.6471H110V82ZM110 61.4118H46V42.5882H110V61.4118ZM110 38.3529H46V22C46 20.8954 46.8954 20 48 20H108C109.105 20 110 20.8954 110 22V38.3529Z"
          fill="#3A393B"
        />
      </g>
      <g filter="url(#filter1_d_2369_56500)">
        <path
          d="M181 82C181 83.1046 180.105 84 179 84H120C118.895 84 118 83.1046 118 82V65.6471H181V82ZM181 61.4118H118V42.5882H181V61.4118ZM181 38.3529H118V22C118 20.8954 118.895 20 120 20H179C180.105 20 181 20.8954 181 22V38.3529Z"
          fill="#3A393B"
        />
      </g>
      <g filter="url(#filter2_d_2369_56500)">
        <path
          d="M252 82C252 83.1046 251.105 84 250 84H191C189.895 84 189 83.1046 189 82V65.6471H252V82ZM252 61.4118H189V42.5882H252V61.4118ZM252 38.3529H189V22C189 20.8954 189.895 20 191 20H250C251.105 20 252 20.8954 252 22V38.3529Z"
          fill="#3A393B"
        />
      </g>
      <g filter="url(#filter3_d_2369_56500)">
        <path
          d="M323 82C323 83.1046 322.105 84 321 84H262C260.895 84 260 83.1046 260 82V65.6471H323V82ZM323 61.4118H260V42.5882H323V61.4118ZM323 38.3529H260V22C260 20.8954 260.895 20 262 20H321C322.105 20 323 20.8954 323 22V38.3529Z"
          fill="#3A393B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2369_56500"
          x="43"
          y="18"
          width="67"
          height="66"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-3" dy="-2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2369_56500"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2369_56500"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2369_56500"
          x="115"
          y="18"
          width="66"
          height="66"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-3" dy="-2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2369_56500"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2369_56500"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2369_56500"
          x="186"
          y="18"
          width="66"
          height="66"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-3" dy="-2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2369_56500"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2369_56500"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_2369_56500"
          x="257"
          y="18"
          width="66"
          height="66"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-3" dy="-2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.12549 0 0 0 0 0.278431 0 0 0 0.68 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2369_56500"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2369_56500"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </SVGWrapper>
)
export default OverviewTableIcon
