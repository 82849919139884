import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const AutoRenew = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        fill="#fff"
        d="M5.175 15.625q-.45-.85-.687-1.75-.238-.9-.238-1.825 0-3.25 2.263-5.525Q8.775 4.25 12 4.25h.775l-1.85-1.85 1.05-1.05L15.65 5l-3.675 3.65-1.05-1.05 1.85-1.85H12q-2.6 0-4.425 1.837Q5.75 9.425 5.75 12.05q0 .625.138 1.237.137.613.412 1.213Zm6.85 7.025L8.35 19l3.675-3.65 1.05 1.05-1.85 1.85H12q2.6 0 4.425-1.837 1.825-1.838 1.825-4.463 0-.625-.137-1.238-.138-.612-.413-1.212l1.125-1.125q.45.85.688 1.75.237.9.237 1.825 0 3.25-2.262 5.525Q15.225 19.75 12 19.75h-.775l1.85 1.85Z"
      />
    </svg>
  </SVGWrapper>
)
export default AutoRenew
