import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PasswordSetForm, resetPassword } from '../../api/user'
import {
  LoginCard,
  PageWrapper,
  WelcomeText,
} from '../../components/LoginComponents'
import Divider from '../../components/Divider'
import { HeadingMedium, HeadingSmallest } from '../../components/Text'
import { TextField } from '../../components/InputFields'
import theme from '../../Theme'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import GreenCheckCircle from '../../assets/GreenCheckCircle'

// TODO
// Create reusable component for Set, Reset Password
export default function ResetPassword() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [formData, setFormData] = useState<PasswordSetForm>({
    email: email,
    password: '',
    token: token,
  })

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const resp = await resetPassword(formData)
    if (resp.error) {
      setPasswordError(resp.error)
      return
    }
    setShowSuccessModal(true)
  }

  const handleConfirmPasswordChange = (value: any) => {
    setConfirmPassword(value)
  }

  const handleChange = (value: string, name: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  useEffect(() => {
    setPasswordError('')
    if (confirmPassword === '') return
    if (
      !formData.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?^~()\-_=+./|<>,:[\]{}])[A-Za-z\d#$@!%&*?^~()\-_=+./|<>,:[\]{}]{12,50}$/
      )
    ) {
      setPasswordError(
        'Password must be at least 12 characters long and contain at least one lowercase letter, one uppercase letter, one number and one special character.'
      )
    }
    if (formData.password !== confirmPassword) {
      setPasswordError('Passwords do not match')
    }
  }, [formData.password, confirmPassword])

  const shouldDisableSubmit = () => {
    return (
      !token ||
      !formData.email ||
      !formData.password ||
      formData.password !== confirmPassword
    )
  }

  return (
    <PageWrapper>
      {showSuccessModal && <SuccessModal />}
      <LoginCard style={{ width: '510px' }}>
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <WelcomeText>Welcome to Diminish</WelcomeText>
          <Divider style={{ margin: '16px 0' }} />
          <HeadingSmallest color="textSubdued" style={{ marginBottom: '24px' }}>
            Please create your password to continue to Diminish.
          </HeadingSmallest>
          <TextField
            label="Email"
            type="text"
            name="email"
            placeholder={email ? email : ''}
            onChange={(v) => handleChange(v, 'email')}
            disabled={true}
            marginBottom="34px"
          />
          <TextField
            label="Create Password"
            type="password"
            name="password"
            placeholder="Enter your password"
            onChange={(v) => handleChange(v, 'password')}
          />
          <span
            style={{
              fontSize: '12px',
              color: theme.color.textSubdued,
              margin: '4px 0 34px',
            }}
          >
            Use 12 or more characters with a mix of lower case, uper case
            letters, numbers and special characters
          </span>
          <TextField
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            placeholder="Enter your password"
            onChange={(v) => handleConfirmPasswordChange(v)}
            marginBottom="34px"
            errorMsg={passwordError}
          />
          <Button disabled={shouldDisableSubmit()} type="submit">
            Create Password
          </Button>
        </form>
      </LoginCard>
    </PageWrapper>
  )
}

const SuccessModal = () => {
  const navigate = useNavigate()
  return (
    <Modal
      style={{
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <GreenCheckCircle style={{ height: '24px', width: '24px' }} />
        <HeadingMedium color="textDefault">Congratulations</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>
        You have successfully created a password.
        <br />
        Use your password to login to your Diminish account.
      </HeadingSmallest>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => navigate('/login')}
          style={{
            width: '169px',
            backgroundColor: theme.color.successGreen,
            border: theme.color.successGreen,
          }}
        >
          Continue to Log in
        </Button>
      </div>
    </Modal>
  )
}
