import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'
import theme from '../Theme'

// red circle with a white X inside
const RedErrorCircle = ({
  style,
  transform,
}: {
  style?: CSSProperties
  transform?: string
}) => (
  <SVGWrapper style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={theme.color.errorRed}
    >
      <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
    </svg>
  </SVGWrapper>
)
export default RedErrorCircle
