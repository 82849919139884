import React, { useEffect, useRef, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import theme from '../Theme'
import { OptionsWrapper, Option } from './Dropdown'
import SearchIcon from '../assets/Search.svg'

const InputWrapper = styled('div')<{ marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '')};
`

const StyledInput = styled('input')<{
  disabled?: boolean
  error?: string
}>`
  display: block;
  min-height: 24px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${({ error }) =>
    error ? theme.color.errorRed : theme.color.border};
  font-size: 14px;
  flex-grow: 0;
  &:hover,  &:focus  {
    border: ${({ disabled }) =>
      disabled ? '' : `1px solid ${theme.color.textPurple};`}
    outline: none !important;
    caret-color: ${theme.color.textPurple};
  }
`

export const TextField = ({
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  disabled,
  marginBottom,
  errorMsg,
}: {
  label: string
  type?: string
  name?: string
  value?: string
  placeholder?: string
  onChange: (newInput: string, name?: string) => void
  disabled?: boolean
  marginBottom?: string
  errorMsg?: string
}) => (
  <InputWrapper style={{ marginBottom }}>
    <label
      htmlFor={name}
      style={{ color: theme.color.textDefault, fontSize: '12px' }}
    >
      {label}
    </label>
    <StyledInput
      aria-label={label}
      role={type}
      type={type}
      id={name}
      name={name}
      value={value}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value, name)}
      placeholder={placeholder}
      error={errorMsg}
    />
    {errorMsg ? (
      <span style={{ fontSize: '12px', color: theme.color.errorRed }}>
        {errorMsg}
      </span>
    ) : (
      <></>
    )}
  </InputWrapper>
)

const StyledFilterInput = styled(StyledInput)({
  backgroundImage: `url(${SearchIcon})`,
  backgroundPosition: `10px 12px`,
  backgroundRepeat: `no-repeat`,
  paddingLeft: '40px',
})

export const FilteredTextField = ({
  label,
  placeholder,
  value,
  options,
  onSelect,
  marginBottom,
  style,
}: {
  label: string
  placeholder?: string
  value?: string
  options: string[]
  onSelect: (selected: string) => void
  marginBottom?: string
  style?: CSSProperties
}) => {
  const ref = useRef<any>()

  const [showOptions, setShowOptions] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options)

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  const handleInputChange = (event: any) => {
    const filtered = options.filter((o) =>
      o.toUpperCase().includes(event.target.value.toUpperCase())
    )
    setFilteredOptions(filtered)
    setShowOptions(true)
  }

  return (
    <InputWrapper style={style} marginBottom={marginBottom}>
      <label
        style={{ color: theme.color.textDefault, fontSize: '12px' }}
        onClick={() => setShowOptions(!showOptions)}
      >
        {label}
      </label>
      <StyledFilterInput
        aria-label={label}
        defaultValue={value}
        ref={ref}
        placeholder={placeholder}
        onKeyUp={handleInputChange}
        onFocus={() => {
          setShowOptions(true)
        }}
      />

      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            {filteredOptions.map((o, i) => (
              <Option
                key={i}
                onClick={() => {
                  if (ref.current) {
                    ref.current.value = o
                  }
                  setShowOptions(false)
                  onSelect(o)
                }}
              >
                {o}
              </Option>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </InputWrapper>
  )
}
