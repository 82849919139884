import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export const showToast = (message: string, type: ToastType) => {
  switch (type) {
    case ToastType.SUCCESS:
      toast.success(message)
      break
    case ToastType.ERROR:
      toast.error(message)
      break
    case ToastType.INFO:
      toast.info(message)
      break
    case ToastType.WARNING:
      toast.warning(message)
      break
    default:
      toast(message)
  }
}
