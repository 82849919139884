import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const BarChart = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path fill="#fff" d="M4.5 19.5v-10h3v10Zm6 0v-15h3v15Zm6 0v-6h3v6Z" />
    </svg>
  </SVGWrapper>
)
export default BarChart
