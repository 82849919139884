import { CSSProperties } from 'react'
import SVGWrapper from './SVGWrapper'

const CalendarEvent = ({ style }: { style?: CSSProperties }) => (
  <SVGWrapper style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        fill="#fff"
        d="M14.7 18q-.975 0-1.65-.675-.675-.675-.675-1.625 0-.975.675-1.65.675-.675 1.65-.675.95 0 1.625.675T17 15.7q0 .95-.675 1.625T14.7 18Zm-9.4 3.5q-.75 0-1.275-.525Q3.5 20.45 3.5 19.7V6.3q0-.75.525-1.275Q4.55 4.5 5.3 4.5h1.4V2.375h1.525V4.5H15.8V2.375h1.5V4.5h1.4q.75 0 1.275.525.525.525.525 1.275v13.4q0 .75-.525 1.275-.525.525-1.275.525Zm0-1.5h13.4q.1 0 .2-.1t.1-.2v-9.4H5v9.4q0 .1.1.2t.2.1ZM5 8.8h14V6.3q0-.1-.1-.2t-.2-.1H5.3q-.1 0-.2.1t-.1.2Zm0 0V6v2.8Z"
      />
    </svg>
  </SVGWrapper>
)
export default CalendarEvent
