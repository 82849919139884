import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from '../Theme'
import ProfileIcon from '../assets/Person'
import { SVGType } from '../assets/SVGWrapper'
import { LogoWithFallback } from './Logo'
import { Option, OptionsWrapper } from './Dropdown'
import useAuth from '../hooks/useAuth'

const ProfileWrapper = styled('div')<{ size: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) =>
    size === 'small' ? '24px' : size === 'medium' ? '40px' : '80px'};
  height: ${({ size }) =>
    size === 'small' ? '24px' : size === 'medium' ? '40px' : '80px'};
  border: 2px solid ${theme.color.border};
  border-radius: 50%;
  background-color: ${theme.color.backgroundSubdued};
  color: ${theme.color.textSubdued};
  margin-right: 8px;
  font-size: ${({ size }) =>
    size === 'small' ? '16px' : size === 'medium' ? '20px' : '40px'};
  & > svg {
    width: 24px;
    height: 24px;
    color: ${theme.color.textSubdued};
  }
`

const ProfileDropdownWrapper = styled('div')`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
`

export const ProfileDropdown = ({
  name,
  size,
  src,
  logout,
}: {
  name: string
  size?: string
  src?: string
  logout: () => void
}) => {
  const { permissions, canImpersonate } = useAuth()
  const ref = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowDropdown(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showDropdown])

  return (
    <ProfileDropdownWrapper onClick={() => setShowDropdown(!showDropdown)}>
      <Profile r={ref} name={name} src={src} size={size} />
      {showDropdown && (
        <div style={{ position: 'relative', width: '200px' }}>
          <OptionsWrapper>
            <Option onClick={() => (window.location.href = '/profile')}>
              Profile
            </Option>
            {permissions && permissions.see_settings && (
              <Option onClick={() => (window.location.href = '/settings')}>
                Settings
              </Option>
            )}
            {canImpersonate && (
              <Option onClick={() => (window.location.href = '/impersonate')}>
                Impersonate
              </Option>
            )}

            <Option onClick={logout}>Log out</Option>
          </OptionsWrapper>
        </div>
      )}
    </ProfileDropdownWrapper>
  )
}

export const Profile = ({
  name,
  size,
  src,
  r,
}: {
  name: string
  size?: string
  src?: string
  r?: React.MutableRefObject<null>
}) =>
  src ? (
    <>
      <img
        ref={r}
        src={src}
        alt={`${name}-profile`}
        style={{
          marginRight: '8px',
          borderRadius: '50%',
          border: `2px solid ${theme.color.border}`,
          width:
            size === 'small' ? '24px' : size === 'medium' ? '40px' : '80px',
        }}
      />
    </>
  ) : (
    <ProfileWrapper ref={r} size={size || 'small'}>
      {name ? name[0].toUpperCase() : <ProfileIcon />}
    </ProfileWrapper>
  )

export const IconWrapper = ({ Icon }: { Icon: SVGType }) => (
  <div
    style={{
      height: '24px',
      width: '24px',
      borderRadius: '50%',
      backgroundColor: theme.color.backgroundPurple,
      color: 'white',
    }}
  >
    <Icon style={{ height: '5px', width: '5px' }} />
  </div>
)

const AppImageWrapper = styled('div')<{ logo_url: string | undefined }>`
  display: inline-block;
  border: 1px solid ${theme.color.border};
  height: 32px;
  width: 32px;
  padding: 2px;
  border-radius: 4px;
  background-color: ${({ logo_url }) =>
    logo_url ? theme.color.white : theme.color.backgroundSubdued};
  margin-right: 4px;
`

export const AppImage = ({
  logo_url,
  name,
}: {
  logo_url: string | undefined
  name: string
}) => (
  <AppImageWrapper logo_url={logo_url}>
    <LogoWithFallback
      alt={`${name}-logo`}
      style={{ height: '32px', width: '32px', verticalAlign: 'middle' }}
      src={logo_url}
    />
  </AppImageWrapper>
)
