import { backendRequest } from '../utils/utils'

export type MemberInviteForm = {
  first_name: string
  last_name: string
  email: string
  role_id: number
  org_id: number | undefined
}

export type LoginForm = {
  email: string
  password: string
}

export type User = {
  user_id: number
  org_id: number
  first_name: string
  last_name: string
  email: string
  profile_picture: string
  hash: string
}

type Role = {
  id: number
  name: string
}

export type Roles = Array<Role>

export type Permissions = {
  see_usage: boolean
  see_users: boolean
  add_members: boolean
  add_managers_admins: boolean
  edit_managers_admins: boolean
  edit_members: boolean
  see_integrations: boolean
  add_integrations: boolean
  remove_integrations: boolean
  see_contracts: boolean
  add_contracts: boolean
  edit_contracts: boolean
  see_reports: boolean
  see_settings: boolean
  see_tracking_urls: boolean
  add_tracking_urls: boolean
  can_impersonate: boolean
}

export type PasswordSetForm = {
  email: string | null
  password: string
  token: string | null
}

// TODO
// create a backend endpoint calling wrapper
export async function login(loginData: LoginForm) {
  const userRes = await fetch(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/login`,
    {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(loginData),
    }
  )
  return await userRes.json()
}

export async function getCurrentUser() {
  const userRes = await fetch(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/userinfo`,
    { credentials: 'include' }
  )
  return userRes.json()
}

export async function logout() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/logout`,
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.json()
}

export async function permissions(): Promise<Permissions | { error: string }> {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/permissions`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.json()
}

export async function getRoles(): Promise<Roles> {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_ENDPOINT}/roles`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.json()
}

export async function inviteUsers(
  formData: MemberInviteForm,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
): Promise<any> {
  let membersToAppUser = `/users/member_to_app_user`

  if (impersonate && reqOrgId !== -1 && reqUserId !== -1) {
    membersToAppUser = membersToAppUser.concat(
      `?req_org_id=${reqOrgId}&&impersonate=true&&req_user_id=${reqUserId}`
    )
  }

  return backendRequest(membersToAppUser, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
}

export type RoleChange = {
  email: string
  role_id: number
}

export async function changeUserRole(
  roleChange: RoleChange,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
): Promise<any> {
  let changeRolePath = `/users/change_role`

  if (impersonate && reqOrgId !== -1 && reqUserId !== -1) {
    changeRolePath = changeRolePath.concat(
      `?req_org_id=${reqOrgId}&impersonate=true&req_user_id=${reqUserId}`
    )
  }

  return backendRequest(changeRolePath, {
    method: 'POST',
    body: JSON.stringify(roleChange),
  })
}

export async function setPassword(formData: PasswordSetForm) {
  return backendRequest(`/set_password`, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
}

export async function resetPassword(formData: PasswordSetForm) {
  return backendRequest(`/reset_password`, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
}
